/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { observer, Provider } from 'mobx-react'

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import Axios from "axios";
import AdminLayout from "layouts/Admin.js";
import "./index.css"
import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import UserStore from "./stores/userStore";
import CompanyStore from './stores/companyStore';
import SupervisorLayout from "layouts/Technician";

Axios.defaults.baseURL = "https://api.selffix.co/";
// Axios.defaults.baseURL = "http://192.168.1.128:5001/";


Axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error && error.response && error.response.status === 401) {
    UserStore.logout();
  }
  return error;
});

const App = observer(() => {
  const { user, token } = UserStore;
  if (user || token) {
    if (!user.super) {
      return (
        <Provider userStore={UserStore} companyStore={CompanyStore}>
          <BrowserRouter>
            <Switch>
              <Route path="/supervisor" render={props => <SupervisorLayout {...props} />} />
              <Redirect from="*" to="/supervisor" />
            </Switch>
          </BrowserRouter>
        </Provider>
      )
    } else {
      return (
        <Provider userStore={UserStore} companyStore={CompanyStore}>
          <BrowserRouter>
            <Switch>
              <Route path="/admin" render={props => <AdminLayout {...props} />} />
              {/* <Route path="/admin/app/:id" render={props => <AdminLayout {...props} />} />
              <Route path="/admin" render={props => <AdminLayout {...props} />} /> */}
              <Redirect from="*" to="/admin" />
            </Switch>
          </BrowserRouter>
        </Provider>
      )
    }
  } else {
    return (
      <BrowserRouter>
        <Switch>
          {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
          {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} />} /> */}
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          {/* <Route path="/" render={(props) => <IndexView {...props} />} /> */}
          <Redirect from="*" to="/auth" />
        </Switch>
      </BrowserRouter>
    )
  }
})

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

