/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
// react plugin used to create charts
import moment from 'moment';
// reactstrap components
import ReactDatetime from "react-datetime";

import {
  Badge,
  Button,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { observer } from 'mobx-react'
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import userStore from "stores/userStore";
import strings from "helpers/localization";

const Stats = observer(({ type }) => {
  const { stats, lastCalls, date1, date2 } = userStore;
  const {
    call,
    duration,
    avgDuration,
    resolved,
    notResolved,
    active,
    customerNotConnect,
    resolveRatio,
    notResolveRatio,
    notConnectRatio,
    activeRatio
  } = stats;


  const chartExample5 = {
    data: {
      labels: [strings.resolve, strings.not_resolve, strings.not_connect, strings.active],
      datasets: [
        {
          data: [
            Math.round(resolveRatio),
            Math.round(notResolveRatio),
            Math.round(notConnectRatio),
            Math.round(activeRatio),
          ],
          backgroundColor: [
            "#A6CEE3",
            "#8031BF",
            "#7A63FF",
            "#5E7968",
          ],

        },
      ],
    },
    options: {
      responsive: true,
      legend: {
        position: "top",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  return (
    <>
      <Row>
        <Col lg="5" />
        <Col lg="2" style={{ marginTop: 30 }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span style={{ color: '#C9C9C9' }}>
              {strings.date_range}
            </span>
          </div>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <ReactDatetime
            inputProps={{
              placeholder: "From",
              style: { height: '40px', borderColor: '#C9C9C9', borderRadius: 5 },
            }}
            timeFormat={false}
            onChange={(e) => { userStore.setDate1(e) }}
            closeOnSelect
            dateFormat={"DD/MM/YYYY"}
            renderInput={(props) => {
              return <input {...props} value={(date1 !== '') ? props.value : moment().subtract(7, 'd').format('DD/MM/YYYY')} />
            }}
          />
          <img
            src={require("../../../assets/img/calendar.png").default}
            style={{ width: 20, height: 20, position: 'absolute', top: 10, right: 25 }}
          />
        </Col>
        <div style={{ backgroundColor: '#EBEBEB', width: 1, height: '40px', marginTop: 20 }} />
        <Col style={{ marginTop: 20 }}>
          <ReactDatetime
            inputProps={{
              placeholder: "To",
              style: { height: '40px', borderColor: '#C9C9C9', borderRadius: 5 },
            }}
            timeFormat={false}
            onChange={(e) => { userStore.setDate2(e) }}
            closeOnSelect
            dateFormat={"DD/MM/YYYY"}
            renderInput={(props) => {
              return <input {...props} value={(date2 !== '') ? props.value : moment(new Date()).format('DD/MM/YYYY')
              } />
            }}
          />
          <img
            src={require("../../../assets/img/calendar.png").default}
            style={{ width: 20, height: 20, position: 'absolute', top: 10, right: 25 }}
          />
        </Col>
        <button onClick={async () => { userStore.getStatics(date1, date2) }} style={{ marginRight: 20, height: "40px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "8vw", color: "white", fontSize: "1rem", marginTop: 20, marginRight: 40 }}>
          {strings.apply}
        </button>
      </Row>

      <Row style={{ marginTop: 20, marginRight: 10, marginLeft: 10 }}>
        <div className="custom-col-selffix">
          <Card className="bg-gradient-primary border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    {strings.total_call}
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {call}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle">
                    <i class="fas fa-phone-volume"></i>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="custom-col-selffix">
          <Card className="bg-gradient-primary border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    {strings.resolved_call}
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {resolved}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i class="far fa-thumbs-up"></i>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="custom-col-selffix">
          <Card className="bg-gradient-primary border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    {strings.not_resolved}
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {notResolved}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i class="fas fa-times"></i>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className="custom-col-selffix" >
          <Card className="bg-gradient-primary border-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    className="text-uppercase text-muted mb-0 text-white"
                    tag="h5"
                  >
                    {strings.customer_not_connected}
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {customerNotConnect}
                  </span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                    <i class="fas fa-plug"></i>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row>
        {/* <Col xl="8" style={{ margin: 'auto' }}>
          <Card>
            <CardHeader>
              <h6 className="surtitle">{strings.calls}</h6>
              <h5 className="h3 mb-0">{strings.calls_state_overview}</h5>
            </CardHeader>
            <CardBody>
              <Doughnut
                data={chartExample5.data}
                options={chartExample5.options}
                className="chart-canvas"
                id="chart-doughnut"
              />
            </CardBody>
          </Card>
        </Col> */}
        <Col xl="12">
          <Card>
            <CardHeader>
              <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                <h5 className="h3 mb-0">{strings.last_calls}</h5>
              </div>
            </CardHeader>
            <CardBody>
              <ListGroup className="list my--3" flush>
                {
                  lastCalls.map((c) => {
                    return (
                      <ListGroupItem className="px-0">
                        <Row>
                          <Col>
                            <h3 className="h4 mb-0">{c.company ? c.company.name : "-"}</h3>
                          </Col>
                          <Col>
                            <h3 className="h4 mb-0">{c.technician ? c.technician.email : ""}</h3>
                          </Col>
                          <Col>
                            <h3 className="h4 mb-0">{strings[c.state]}</h3>
                          </Col>
                          <Col>
                            <h3 className="h4 mb-0">{moment(c.createdAt).format("DD-MM-YYYY HH:mm")}</h3>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )
                  })
                }
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
})

class Dashboard extends React.Component {

  componentWillMount() {
    userStore.setDate1('');
    userStore.setDate2('');
    userStore.getStatics().then(() => {

    });
  }

  render() {

    return (
      <>
        {/* <CardsHeader name="Default" parentName="Dashboards" /> */}
        <Stats />
      </>
    );
  }
}

export default Dashboard;
