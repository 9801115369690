/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import CompanyStore from "../../stores/companyStore";
import strings from "helpers/localization";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { observer } from "mobx-react";
import userStore from "stores/userStore";
import moment from "moment";
import companyStore from "../../stores/companyStore";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 15,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {strings.show}{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        }
      </label>
    </div>
  )
});

const Agents = observer(() => {
  const { agents } = CompanyStore;
  return (
    <CardBody>
      <ToolkitProvider
        data={agents}
        keyField="id"
        columns={[
          {
            dataField: "online",
            sort: true,
            text: strings.online,
            formatter: (data, data2) => {
              return (
                <div style={{ backgroundColor: data ? "green" : "red", width: 12, height: 12, borderRadius: 20 }} />
              )
            }
          },
          {
            dataField: "name",
            sort: true,
            text: strings.name,
            formatter: (data, data2) => {
              return (
                <span>
                  {data}
                </span>
              )
            }
          },
          {
            dataField: "surname",
            sort: true,
            text: strings.surname,
          },
          {
            dataField: "isManager",
            text: strings.manager,
            sort: true,
            formatter: (data) => {
              return (
                <span>
                  {data ? strings.true : strings.false}
                </span>
              )
            }
          },
          {
            dataField: "createdAt",
            sort: true,
            text: strings.created_date,
            formatter: (data) => {
              return (
                <a>
                  {moment(data).format("DD-MM-YYYY")}
                </a>
              )
            }
          },
          {
            dataField: "_id",
            sort: true,
            text: strings.actions,
            style: { display: "flex", justifyContent: "center", alignItems: "center" },
            formatter: (data) => {
              return (
                <a
                  onClick={async () => {
                    if (window.confirm(strings.deleteBranch)) {
                      await companyStore.deleteAgent(data);
                      window.location.reload();
                    } else {
                      console.log("-----");
                    }
                  }}
                  style={{ cursor: "pointer", alignSelf: "center" }} >
                  <i className="fas fa-trash" style={{ color: "red" }} ></i>
                </a>
              )
            }
          }
        ]}
        search
      >
        {props => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </CardBody>
  )
})


class BranchDetail extends React.Component {
  async componentDidMount() {
    const { branch_id, company_id } = this.props.match.params
    CompanyStore.setBranch(branch_id)
    CompanyStore.setCompany(company_id)
    await CompanyStore.getAgents();
  }
  constructor(props) {
    super(props);
    this.state = {
      agent_modal: false,
      name: "",
      surname: "",
      isManager: false,
      online: false,
      pushToken: "",
    }
  }

  renderAgentModal = () => {
    return (
      <Modal isOpen={this.state.agent_modal}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({
              agent_modal: false,
              name: "",
              surname: "",
              isManager: false,
              online: false,
              pushToken: "",
            })}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{strings.create_agent}</h6>
        </div>
        <ModalBody>
          <Form style={{ marginTop: 10 }}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.name}
              </label>
              <Input
                placeholder={strings.name}
                type="text"
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.surname}
              </label>
              <Input
                placeholder={strings.surname}
                type="text"
                onChange={(e) => {
                  this.setState({ surname: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup style={{ display: "flex", marginLeft: 24, flexDirection: "row" }}>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.manager}
              </label>
              <Input
                type="checkbox"
                value={this.state.isManager}
                onChange={(e) => {
                  this.setState({ isManager: e.target.checked });
                }}
              />
            </FormGroup >
          </Form>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="default"
            type="button"
            onClick={async () => {
              CompanyStore.createAgent(this.state);
              this.setState({
                agent_modal: false,
                name: "",
                surname: "",
                isManager: false,
                online: false,
                pushToken: "",
              });
            }}
          >
            {strings.create}
          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({
              agent_modal: false,
              name: "",
              surname: "",
              isManager: false,
              online: false,
              pushToken: "",
            })}
          >
            {strings.close}
          </Button>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div style={{ display: 'flex', flex: 1, justifyContent: "space-between", alignItems: "center" }}>
                  <CardTitle style={{ margin: 0 }} tag="h4">{strings.branch_agent_list}</CardTitle>
                  <div>
                    <button onClick={async () => {
                      this.setState({ agent_modal: true })
                    }} style={{ marginRight: 20, height: "45px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1rem" }}>
                      {strings.create_agent}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <Agents />
            </Card>
          </Col>
        </Row>
        {this.renderAgentModal()}
      </div>
    );
  }
}

export default observer(BranchDetail);

// const Dashboard = () => {

// };

// export default Dashboard;
