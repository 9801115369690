export const tr = {
	"welcome": "HOŞ GELDİNİZ!",
	"logout": "Çıkış",
	"welcome_selffix": "SELFFIX'e Hoş Geldiniz",
	"welcome_description": "E-posta adresiniz ve şifrenizle giriş yapın",
	"sign_in": "Giriş",
	"email_address": "E-posta Adresi",
	"email": "E-posta",
	"password": "Şifre",
	"login": "Giriş",
	"home": "Ana Sayfa",
	"company_list": "Şirket Listesi",
	"total_call": "TOPLAM GÖRÜŞME",
	"resolved_call": "ÇÖZÜLEN GÖRÜŞME",
	"not_resolved": "Çözülmedi",
	"customer_not_connected": "MÜŞTERİ BAĞLANAMADI",
	"customer_not_connect": "Müşteri Bağlı Değil",
	"last_calls": "Son Görüşmeler",
	"calls": "GÖRÜŞMELER",
	"calls_state_overview": "Görüşme Durumu Özeti",
	"resolve": "Çözümle",
	"not_resolve": "Çözülmemiş",
	"not_connect": "Bağlanamıyor",
	"active": "Aktif",
	"companies": "Şirketler",
	"create_company": "Şirket Oluştur",
	"create": "Oluştur",
	"close": "Kapat",
	"id": "Kimlik",
	"company_name": "Şirket Adı",
	"name": "Adı",
	"surname": "Soyadı",
	"supervisors_count": "Denetçi Sayısı",
	"created_date": "Oluşturma Tarihi",
	"company_technicians_list": "Şirket Teknisyen Listesi",
	"create_technician": "Teknisyen Oluştur",
	"changes_saved": "Değişiklikler Kaydedildi",
	"technicians": "Teknisyenler",
	"edit_technicians": "Teknisyeni Düzenle",
	"supervisors": "Denetçiler",
	"create_supervisors": "Denetçi Oluştur",
	"company_supervisor_list": "Şirket Denetçi Listesi",
	"technician_call_list": "Teknisyen Görüşme Listesi",
	"customer": "Müşteri",
	"duration": "Süre",
	"status": "Durum",
	"resolved": "Çözüldü",
	"company": "Şirket",
	"call_id": "Kimlik No.",
	"technician": "Teknisyen",
	"start_call": "Görüşmenin Başlangıcı (Tarih/Saat)",
	"end_call": "Görüşmenin Sonu (Tarih/Saat)",
	"call_duration": "Görüşme Süresi",
	"customer_phone": "Müşteri Telefon No.",
	"final_condition": "Son Durum",
	"spare_part": "Yedek Parça",
	"yes": "Evet",
	"no": "Hayır",
	"show": "Göster",
	"all": "Tümü",
	"end": "Son",
	"waiting": "Beklemede",
	"resolve_status": "Çözümlenme Durumu",
	"min_date": "Başlangıç Tarihi",
	"max_date": "Bitiş Tarihi",
	"clear_filters": "Filtreleri Temizle",
	"search": "Ara",
	"select_language": "Dil Seç",
	"turkish": "Türkçe",
	"english": "İngilizce",
	"german": "Almanca",
	"no_rating": "Derecelendirme Yok",
	"no_comment": "Yorum Yok",
	"customers_rate": "Müşteri Memnuniyet Derecesi",
	"technicians_rate": "Teknisyen Derecesi",
	"technician_call_comments": "Teknisyen Görüşme Yorumu",
	"privacy_policy": "Gizlilik Politikası URL",
	"tech_note": "Teknisyen Notu",
	"rating_avi": "Derecelendirmeyi Göster",
	"create_branch": "Şube Oluştur",
	"branch_name": "Şube Adı",
	"prompter": "Danışman",
	"branches": "Şubeler",
	"branch_agent_list": "Şube Temsilcileri Listesi",
	"create_agent": "Temsilci Oluştur",
	"manager": "Yönetici",
	"online": "Çevrimiçi",
	"createKey": "Kod Oluştur",
	"actions": "Eylemler",
	"deleteBranch": "Bu şubeyi silmek istediğinizden emin misiniz?",
	"deleteAgent": "Bu temsilciyi silmek istediğinizden emin misiniz?",
	"agent_name": "Temsilci Adı",
	"customer_name": "Müşteri Adı",
	"customer_mail": "Müşteri E-Postası",
	"created_at": "Oluşturulma Tarihi",
	"waiting_time": "Bekleme Süresi",
	"active_time": "Aktif Süre",
	"agent_rating": "Temsilci Derecelendirmesi",
	"agent_comment": "Temsilci Yorumu",
	"customer_rating": "Müşteri Derecelendirmesi",
	"customer_comment": "Müşteri Yorumu",
	"accepted_call": "Kabul Edilen Çağrı",
	"total_call_duration": "Toplam Çağrı Süresi",
	"avg_call_duration": "Ortalama Çağrı Süresi",
	"avg_waiting_duration": "Ortalama Bekleme Süresi",
	"total_waiting_duration": "Toplam Bekleme Süresi",
	"avg_waiting_for_accepted": "Kabul Edilen İçin Ortalama Bekleme Süresi",
	"max_waiting_duration": "Maksimum Bekleme Süresi",
	"total_waiting_for_accepted": "Kabul Edilen İçin Toplam Bekleme Süresi",
	"avg_agent_rating": "Ortalama Temsilci Derecelendirmesi",
	"avg_customer_rating": "Ortalama Müşteri Derecelendirmesi",
	"accept_rate": "Kabul Oranı",
	"TRUE": "TRUE",
	"FALSE": "FALSE",
	"remote_support": "Uzaktan Destek",
	"customize_remote_support": "Uzaktan Desteği Özelleştir",
	"colors": "Renkler",
	"gdpr": "KVKK",
	"primary_color": "Birincil Renk",
	"privacy_url": "KVKK URL'si",
	"privacy_text": "KVKK Metni",
	"privacy_warning": "KVKK Uyarı Metni",
	"save": "Kaydet",
	"messages": "Mesajlar",
	"error_text": "Metnin ${url} ve ${pin} metinlerini içerdiğinden emin olun. Örneğin: Selffix Yardim Linki: ${url} Kod: ${pin}",
	"error_text_with_privacy": "Metnin ${url}, ${pin} ve ${privacy} metinlerini içerdiğinden emin olun. Örneğin: Selffix Yardim Linki: ${url} Kod: ${pin} Gizlilik:${privacy}",
	"sms_privacy": "Gönderilen SMS'te gizlilik politikasını göster",
	"sms_privacy_url": "SMS'te yer alacak gizlilik politikasının adresi",
	"date_range": "Tarih Aralığı",
	"apply": "Uygula",
	"file_size_large": "Dosya yüklemek için fazla büyük!",
	"logo": "Logo",
	"cancel": "İptal"
}