/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/examples/Login.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";

import Companies from './views/pages/Companies.js';
import CompanyDetail from './views/pages/CompanyDetail.js';
import TechnicianDetail from './views/pages/TechnicianDetail.js';
import strings from './helpers/localization'
import CallDetail from "views/pages/CallDetail.js";
import BranchDetail from "views/pages/BranchDetail.js";
import LiveStoreDetail from "views/pages/livestore/index.js";
import CustomizeRemoteSupport from "views/pages/CustomizeRemoteSupport.js";
const routes = [
  {
    path: "/home",
    name: strings.home,
    mini: "C-L",
    icon: "fas fa-chart-pie",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/companies/all",
    name: strings.company_list,
    mini: "C-L",
    icon: "fas fa-building",
    component: Companies,
    layout: "/admin",
  },
  {
    path: "/company/detail",
    name: strings.remote_support,
    mini: "C-L",
    icon: "fas fa-building",
    component: CompanyDetail,
    layout: "/supervisor",
  },
  {
    path: "/company/detail",
    name: strings.remote_support,
    mini: "C-D",
    component: CompanyDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    icon: "fas fa-edit",
    path: "/company/customize/:companyId",
    name: strings.customize_remote_support,
    mini: "C-D",
    component: CustomizeRemoteSupport,
    layout: "/admin",
    invisible: true,
  },
  {
    icon: "fas fa-edit",
    path: "/company/customize",
    name: strings.customize_remote_support,
    mini: "C-D",
    component: CustomizeRemoteSupport,
    layout: "/supervisor",
  },
  {
    path: "/live-store",
    name: "Live Store",
    icon: "fas fa-stream",
    mini: "C-D",
    layout: "/admin",
    component: LiveStoreDetail,
  },
  {
    path: "/technician/detail/call/:id",
    name: "Call Detail",
    mini: "C-D",
    component: CallDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/technician/detail/call/:id",
    name: "Call Detail",
    mini: "C-D",
    component: CallDetail,
    layout: "/supervisor",
    invisible: true,
  },
  {
    path: "/live-store",
    name: "Live Store",
    icon: "fas fa-stream",
    layout: "/supervisor",
    mini: "C-D",
    component: LiveStoreDetail,
  },
  {
    path: "/technician/detail",
    name: "Technician Detail",
    mini: "T-D",
    component: TechnicianDetail,
    layout: "/supervisor",
    invisible: true,
  },
  {
    path: "/technician/detail",
    name: "Technician Detail",
    mini: "T-D",
    component: TechnicianDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/branch/detail/:company_id/:branch_id",
    name: "Technician Detail",
    mini: "T-D",
    component: BranchDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/branch/detail/:company_id/:branch_id",
    name: "Technician Detail",
    mini: "T-D",
    component: BranchDetail,
    layout: "/supervisor",
    invisible: true,
  },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      // {
      //   path: "/pricing",
      //   name: "Pricing",
      //   miniName: "P",
      //   component: Pricing,
      //   layout: "/auth",
      // },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      // {
      //   path: "/register",
      //   name: "Register",
      //   miniName: "R",
      //   component: Register,
      //   layout: "/auth",
      // },
      // {
      //   path: "/lock",
      //   name: "Lock",
      //   miniName: "L",
      //   component: Lock,
      //   layout: "/auth",
      // },
      // {
      //   path: "/timeline",
      //   name: "Timeline",
      //   miniName: "T",
      //   component: Timeline,
      //   layout: "/admin",
      // },
      // {
      //   path: "/profile",
      //   name: "Profile",
      //   miniName: "P",
      //   component: Profile,
      //   layout: "/admin",
      // },
      // {
      //   path: "/rtl-support",
      //   name: "RTL Support",
      //   miniName: "RS",
      //   component: RTLSupport,
      //   layout: "/rtl",
      // },
    ],
  },
];

export default routes;
