import { makeAutoObservable } from "mobx"
import Axios from "axios";
import AWS from "aws-sdk";
import userStore from "./userStore";
import moment from "moment";
import strings from "helpers/localization";

const D_O_S_A = process.env.REACT_APP_DIGITAL_OCEAN_SPACE_ACCESS;
const D_O_S_S = process.env.REACT_APP_DIGITAL_OCEAN_SPACE_SECRET;

const spacesEndpoint = new AWS.Endpoint("fra1.digitaloceanspaces.com");

const s3 = new AWS.S3({
  accessKeyId: D_O_S_A,
  secretAccessKey: D_O_S_S,
  endpoint: spacesEndpoint
});

class CompanyStore {

  technicians = null;
  supervisors = null;
  company = null;
  companies = [];
  technician = null;
  technicianId = null;
  call = null;
  calls = null;
  calls_backup = null;
  duration = "";
  messages = "";
  email = null;
  name = null;
  surname = null;
  password = null;
  selected_type = "Technicians";
  selected_status = "All";
  selected_resolve_status = "All"
  selected_status_text = strings.all
  resolve_filter = false;
  status_filter = false;
  start_filter = false;
  end_filter = false;
  startDate = new Date();
  endDate = new Date();
  companyName = null;
  privacyUrl = null;
  showRating = false;
  companyLang = "en";
  stats = null;
  companyExcell = null;
  branches = [];
  agents = [];

  constructor() {
    makeAutoObservable(this);
  }


  uploadFile(file, name) {
    return new Promise((resolve) => {
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: "selffix",
        ContentType: file.type,
        Key: `/logos/${name}/${file.name}`,
      };
      // console.log("🚀 ~ file: index.jsx ~ line 57 ~ returnnewPromise ~ params", params)

      s3.putObject(params)
        .on("build", (request) => {
          // request.httpRequest.headers.Host = "https://selffix.digitaloceanspaces.com";
          // request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
          request.httpRequest.headers["Content-Length"] = file.size;
          request.httpRequest.headers["Content-Type"] = file.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
        })
        .send((err, data) => {
          console.log("🚀 ~ CompanyStore ~ .send ~ err:", err)
          if (err) resolve(null);
          else {
            // If there is no error updating the editor with the imageUrl
            const Location = `https://selffix.fra1.cdn.digitaloceanspaces.com/${params.Key}`;
            resolve({ Location });
          }
        });
    });
  }

  setExcel = (flag = true) => {
    this.companyExcell = [{ company: "1" }, { company: "2" }]
  }

  clearTechnician = () => {
    this.email = null;
    this.name = null;
    this.password = null;
    this.surname = null;
  }

  setSelectedType = async (text) => {
    try {
      this.selected_type = text
      if (text === "Supervisors") {
        await this.getSupervisors()
      } else {
        await this.getTechnicians()
      }
    } catch (error) {

    }
  }

  resetFilter = () => {
    this.calls = this.calls_backup;
    this.status_filter = false;
    this.resolve_filter = false;
    this.start_filter = false;
    this.end_filter = false;
    this.resolve_filter = "All";
    this.selected_status = "All";
    this.selected_status_text = strings.all
    this.selected_start_date = '';
    this.selected_end_date = '';
  }

  setShowRating = (showRating) => {
    this.showRating = showRating
  }

  filter = () => {
    const { resolve_filter, status_filter, start_filter, end_filter,
      selected_end_date, selected_resolve_status, selected_start_date, selected_status } = this;
    this.calls = this.calls_backup
    if (resolve_filter && selected_resolve_status !== "All") {
      this.calls = this.calls.filter(item => item.resolved === selected_resolve_status)
    }
    if (status_filter && selected_status !== "All") {
      this.calls = this.calls.filter(item => item.state === selected_status)
    }
    if (start_filter) {
      this.calls = this.calls.filter(item => new Date(item.createdAt) >= new Date(selected_start_date))
    }
    if (end_filter) {
      this.calls = this.calls.filter(item => new Date(item.createdAt) <= new Date(selected_end_date))
    }
  }

  setResolveType = (status) => {
    this.resolve_filter = true;
    this.selected_resolve_status = status
    this.filter()
    // if (!this.status_ && !this.resolve_status && !this.start_status && !this.end_status) {
    //   this.resolve_status = true;
    //   this.selected_resolve_status = status ? "True" : "False"
    //   this.calls = this.calls_backup.filter(item => item.resolved === status)
    // } else {
    //   this.selected_resolve_status = status ? "True" : "False"
    //   this.calls = this.calls.filter(item => item.resolved === status)
    // }
  }

  setStatusType = (status, text) => {
    this.status_filter = true;
    this.selected_status = status;
    this.selected_status_text = text
    this.filter()

    // if (!this.status_ && !this.resolve_status && !this.start_status && !this.end_status) {
    //   this.status_ = true;
    //   this.selected_status = status
    //   this.calls = this.calls_backup.filter(item => item.state === status)
    // } else {
    //   this.selected_status = status
    //   this.calls = this.calls.filter(item => item.state === status)
    // }
  }

  setStartDate = (date) => {
    this.start_filter = true;
    this.selected_start_date = date;
    this.filter()

    // if (!this.status_ && !this.resolve_status && !this.start_status && !this.end_status) {
    //   this.start_status = true;
    //   this.selected_start_date = date
    //   this.calls = this.calls_backup.filter(item => new Date(item.createdAt).getTime() > new Date(date).getTime())
    // }
    // else {
    //   this.selected_start_date = date
    //   this.calls = this.calls.filter(item => new Date(item.createdAt).getTime() > new Date(date).getTime())
    // }
  }

  setEndDate = (date) => {
    this.end_filter = true;
    this.selected_end_date = date;
    this.filter()

    // if (!this.status_ && !this.resolve_status && !this.start_status && !this.end_status) {
    //   this.end_status = true;
    //   this.selected_end_date = date
    //   this.calls = this.calls_backup.filter(item => new Date(item.createdAt).getTime() < new Date(date).getTime())
    // } else {
    //   this.selected_end_date = date
    //   this.calls = this.calls.filter(item => new Date(item.createdAt).getTime() < new Date(date).getTime())
    // }
  }

  setName = (text) => {
    this.name = text
  }

  setSurname = (text) => {
    this.surname = text
  }

  setEmail = (text) => {
    this.email = text
  }

  setPassword = (text) => {
    this.password = text
  }

  createTechnician = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company
        const response = await Axios.post("admin/createTechnician", {
          name: this.name, surname: this.surname,
          email: this.email, password: this.password, company: id
        });
        await this.getTechnicians();
        this.clearTechnician()
      } catch (error) {

      }
    })
  }

  updateTechnician = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post("admin/updateTechnician", {
          name: this.name, surname: this.surname,
          email: this.email, password: this.password, _id: id
        });
        alert(strings.changes_saved)
        window.location.reload()
      } catch (error) {

      }
    })
  }

  createSupervisor = async (name, surname, email, password, company) => {
    return new Promise(async (resolve, reject) => {
      try {
        const id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company
        const response = await Axios.post("admin/createSupervisor", {
          name: this.name, surname: this.surname,
          email: this.email, password: this.password, company: id
        });
        await this.getSupervisors();
        this.clearTechnician()
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  getBranches = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company
        const { data } = await Axios.post("admin/getBranches", { company: id });
        console.log('data: ', data);

        if (data.done) {
          this.branches = data.branches;
        }
        resolve(true)
      } catch (error) {

      }
    })
  }

  getBranchesWithId = (id) => {
    return new Promise(async (resolve, reject) => {
      this.company = id;
      // const id = this.company?._id ? this.company._id : JSON.parse(localStorage.getItem("user")).company
      try {
        const { data } = await Axios.post("admin/getBranches", { company: id });
        if (data.done) {
          this.branches = data.branches;
        }
        resolve(true)
      } catch (error) {

      }
    })
  }

  createBranch = (name, prompter, email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company
        const response = await Axios.post("admin/createBranch", {
          name,
          company: id,
          prompter,
          email,
          password,
        });
        await this.getBranches();
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  deleteBranch = (branch_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post("admin/deleteBranch", {
          branch: branch_id,
        });
        console.log('response: ', response);
        resolve(true);
      } catch (error) {
        reject(error)
      }
    })
  }

  getAgents = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const c_id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company
        console.log('c_id: ', c_id);
        const id = this.branch._id ? this.branc._id : this.branch
        console.log('id: ', id);
        const response = await Axios.post("admin/getAgents", {
          company: c_id,
          branch: id,
        });
        console.log('response: ', response);
        this.agents = response.data.agents;
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  createKey = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const c_id = this.company._id ? this.company._id : this.company
        const response = await Axios.post("admin/createKeys", {
          company: c_id,
        });
        this.key = response.data.company.keys.publicKey;
      } catch (error) {
        reject(error)
      }
    })
  }

  createAgent = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { name, surname, isManager } = data;
        const c_id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company
        const id = this.branch._id ? this.branc._id : this.branch
        const response = await Axios.post("admin/createAgent", {
          branch: id,
          company: c_id,
          name,
          surname,
          isManager,
        });
        this.getAgents();
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  deleteAgent = (agent_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post("admin/deleteAgent", {
          agent: agent_id,
        });
        console.log('response: ', response);
        resolve(true);
      } catch (error) {
        console.log('error: ', error);
        reject(error)
      }
    })
  }

  getCompanyStats = async (date1, date2) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { startDate, endDate } = this;
        const _date1 = date1 ? new Date(date1).getTime() : startDate.getTime() - (1000 * 60 * 60 * 24 * 2)
        const _date2 = date2 ? new Date(date2).getTime() : endDate.getTime()
        const id = (this.company && this.company._id) ? this.company._id : JSON.parse(localStorage.getItem("user")).company
        const { data } = await Axios.post("stats/company", {
          company: id, startDate: _date1,
          endDate: _date2
        });
        if (data.done) {
          this.stats = data.stats;
        }
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }


  getTechnicians = async () => {
    return new Promise(async (resolve, reject) => {
      const id = (this.company && this.company._id) ? this.company._id : JSON.parse(localStorage.getItem("user")).company
      try {
        const { data } = await Axios.post("admin/getTechnicians", { company: id });
        if (data.done) {
          this.technicians = data.technicians;
          this.calls = null;
        }
        resolve(true)
      } catch (error) {

      }
    })
  }

  getSupervisors = async () => {
    return new Promise(async (resolve, reject) => {
      const id = this.company ? this.company._id ? this.company._id : this.company : JSON.parse(localStorage.getItem("user")).company

      try {
        const { data } = await Axios.post("admin/getSupervisors", { company: id });
        if (data.done) {
          this.supervisors = data.supervisors;
          this.calls = null;
        }
        resolve(true)
      } catch (error) {

      }
    })
  }

  getTechnicianDetail = async () => {
    return new Promise(async (resolve, reject) => {
      const id = this.technician._id ? this.technician._id : this.technician
      try {
        const { data } = await Axios.post("admin/getTechnicianDetail", { technician: id });
        if (data.done) {
          this.calls = data.calls.map(i => {
            i['company'] = (this.company && this.company.name) ? this.company.name : i.company
            i['technician'] = this.technician.name ? this.technician.name + " " + this.technician.surname : i.technician
            return i;
          });
          this.calls_backup = this.calls
        }
        resolve(true)
      } catch (error) {

      }
    })
  }

  getCallDetail = async (_id) => {
    return new Promise(async (resolve, reject) => {
      const id = this.technician._id ? this.technician._id : this.technician;
      try {
        const { data } = await Axios.post("admin/getTechnicianDetail", { technician: id });
        if (data.done) {
          this.call = data.calls.find((i) => i._id === _id);
        }
        resolve(true)
      } catch (error) {
        console.log("🚀 ~ CompanyStore ~ returnnewPromise ~ error:", error)

      }
    })
  }

  setCompany = (company) => {
    this.company = company
  }

  setTechnician = (technician) => {
    this.technician = technician;
  }

  setBranch = (branch) => {
    this.branch = branch;
  }

  setCompanyName = (name) => {
    this.companyName = name
  }

  setCompanyLang = (lang) => {
    this.companyLang = lang;
  }

  setPrivacyPolicy = (url) => {
    this.privacyUrl = url;
  }

  getCompanies = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        await userStore.getStatics();
        const { data } = await Axios.get("admin/companies");
        if (data.done) {
          this.technicians = null;
          this.supervisors = null;
          this.companies = data.companies
        }
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  createCompany = async () => {
    return new Promise(async (resolve, reject) => {
      const url = this.privacyUrl ? (this.privacyUrl.length === 0 ? null : this.privacyUrl) : null
      try {
        const response = await Axios.post("admin/createCompany", { name: this.companyName, lang: this.companyLang, privacyUrl: url, showRating: this.showRating });
        this.companyName = null;
        this.companyLang = "en";
        this.privacyUrl = null;
        this.showRating = false;
        await this.getCompanies()
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }


}

const companyStore = new CompanyStore();
export default companyStore;