/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link } from "react-router-dom";

import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  CustomInput,
  Input,
  Form,
  FormGroup,
} from "reactstrap";

import { observer } from 'mobx-react'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import userStore from "../../stores/userStore";
import CompanyStore from "../../stores/companyStore";
import moment from "moment";
import Rating from "react-rating"
import ReactExport from "react-export-excel";
import strings from "helpers/localization";
import { useState } from "react";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 15,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        }
      </label>
    </div>
  )
});

const { SearchBar } = Search;

const Columns = [
  {
    dataField: "_id",
    text: "ID",
    formatter: (data, data2) => {
      const { user } = userStore;
      return (
        <Link to={`/${user.super ? "admin" : "supervisor"}/technician/detail/call/${data}`} >
          <a>{data}</a>
        </Link>
      )
    }
  },
  {
    dataField: "customer",
    text: strings.customer
  },
  {
    dataField: "duration",
    text: strings.duration,
    formatter: (data) => {
      return (
        <a>
          {data ? (data.minutes < 10 ? `0${data.minutes}` : data.minutes) : "-"}:{data ? (data.seconds.toFixed(0) < 10 ? `0${data.seconds.toFixed(0)}` : data.seconds.toFixed(0)) : "-"}
        </a>
      )
    },
    sort: true,
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      if (order === 'asc') {
        return a.minutes - b.minutes || a.seconds - b.seconds
      } else {
        return b.minutes - a.minutes || b.seconds - a.seconds
      }
    }
  },
  {
    dataField: "state",
    text: strings.status,
    sort: true,
  },
  {
    dataField: "resolved",
    text: strings.resolved,
    formatter: (data, data2) => {
      if (data2.state === "end") {
        return (
          <img
            src={data ? require("../../assets/img/checked_true.png").default : require("../../assets/img/checked_false.png").default}
            style={{ width: 20, height: 20, }}
          />
        )
      } else {
        return (
          <span>-</span>
        )
      }
    },
    sort: true,
  },
  {
    dataField: "technicianRating",
    text: strings.technicians_rate,
    formatter: (data) => {
      if (data.rating == "-1") {
        return (
          <span>{strings.no_rating}</span>
        )
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <Rating
            initialRating={data.rating}
            readonly
            fractions={10}
            emptySymbol={<img
              src={require("../../assets/img/star_empty.png").default}
              style={{ width: 18, height: 18, marginLeft: 2 }}
            />}
            fullSymbol={<img
              src={require("../../assets/img/star_full.png").default}
              style={{ width: 18, height: 18, marginLeft: 2 }}
            />}
          />
          <span style={{ marginLeft: 10, marginTop: 2 }}>
            {data.rating}
          </span>
        </div>
      )
    },
    sort: true,
  },
  {
    dataField: "technicianRating",
    text: strings.technician_call_comments,
    formatter: (data) => {
      return (
        <a>
          {!data.comment ? strings.no_comment : data.comment}
        </a>
      )
    },
    sort: true,
  },
  {
    dataField: "customerRating",
    text: strings.customers_rate,
    formatter: (data) => {
      if (data == "-1") {
        return (
          <span>{strings.no_rating}</span>
        )
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <Rating
            initialRating={data}
            readonly
            fractions={10}
            emptySymbol={<img
              src={require("../../assets/img/star_empty.png").default}
              style={{ width: 18, height: 18, marginLeft: 2 }}
            />}
            fullSymbol={<img
              src={require("../../assets/img/star_full.png").default}
              style={{ width: 18, height: 18, marginLeft: 2 }}
            />}
          />
          <span style={{ marginLeft: 10, marginTop: 2 }}>
            {data}
          </span>
        </div>
      )
    },
    sort: true,
  },
  {
    dataField: "createdAt",
    text: strings.created_date,
    formatter: (data) => {
      return (
        <a>
          {moment(data).format("DD-MM-YYYY HH:mm")}
        </a>
      )
    },
    sort: true,
  },

]

const StatusSelector = observer(({ type }) => {
  const { selected_status_text } = CompanyStore;
  return (
    <UncontrolledDropdown direction="down" style={{ marginLeft: 20 }}>
      <span style={{ fontSize: 15 }}>Status</span>
      <DropdownToggle
        caret
        className="btn-block"
        color="primary"
        data-toggle="dropdown"
        type="button"
        style={{ height: 45, backgroundColor: '#7A63FF' }}
      >
        {selected_status_text}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={async (e) => { CompanyStore.setStatusType("All", strings.all) }}
        >
          {strings.all}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => { await CompanyStore.setStatusType("end", strings.end) }}
        >
          {strings.end}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => {
            await CompanyStore.setStatusType("customer_not_connect", strings.customer_not_connect)
          }}
        >
          {strings.customer_not_connect}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => {
            await CompanyStore.setStatusType("active", strings.active)
          }}
        >
          {strings.active}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => {
            await CompanyStore.setStatusType("waiting", strings.waiting)
          }}
        >
          {strings.waiting}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
});

const ResolvedSelector = observer(({ type }) => {
  const { selected_resolve_status } = CompanyStore;
  return (
    <UncontrolledDropdown direction="down" style={{ marginLeft: 20, }}>
      <span style={{ fontSize: 15 }}>{strings.resolve_status}</span>
      <DropdownToggle
        caret
        className="btn-block"
        color="primary"
        data-toggle="dropdown"
        type="button"
        style={{ height: 45, backgroundColor: '#7A63FF' }}
      >
        {typeof selected_resolve_status == "string" ? selected_resolve_status : selected_resolve_status ? strings.resolved : strings.not_resolved}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={async (e) => { CompanyStore.setResolveType("All") }}
        >
          {strings.all}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => { CompanyStore.setResolveType(true) }}
        >
          {strings.resolved}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => {
            CompanyStore.setResolveType(false)
          }}
        >
          {strings.not_resolved}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
});


const Calls = observer(({ type }) => {
  const [show_modal, set_show_modal] = useState(false)
  const { calls, selected_end_date, selected_start_date } = CompanyStore
  const technician_id = window.location.pathname.split("/")[4]

  if (!calls) return null;
  return (

    <>
      <CardBody>
        <ToolkitProvider
          data={calls}
          keyField="id"
          columns={Columns}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <div className="py-4 table-responsive">
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1"
                  style={{ display: 'flex', width: "100%" }}
                >
                  <label style={{ marginTop: 25 }}>
                    <SearchBar
                      style={{ height: 45 }}
                      className="form-control-sm"
                      placeholder={strings.search}
                      {...props.searchProps}
                    />
                  </label>
                  <StatusSelector />
                  <ResolvedSelector />
                  <div style={{ marginLeft: 15 }}>
                    <span style={{ marginLeft: 10, fontSize: 15 }}>{strings.min_date}</span>
                    <ReactDatetime
                      inputProps={{
                        placeholder: strings.min_date,
                      }}
                      timeFormat={false}
                      onChange={(e) => CompanyStore.setStartDate(e)}
                      closeOnSelect
                      dateFormat={"DD/MM/YYYY"}
                      renderInput={(props) => {
                        return <input {...props} value={(selected_start_date !== '') ? props.value : ''} />
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <span style={{ marginLeft: 10, fontSize: 15 }}>{strings.max_date}</span>
                    <ReactDatetime
                      inputProps={{
                        placeholder: strings.max_date,
                      }}
                      timeFormat={false}
                      onChange={(e) => CompanyStore.setEndDate(e)}
                      closeOnSelect
                      dateFormat={"DD/MM/YYYY"}
                      renderInput={(props) => {
                        return <input {...props} value={(selected_end_date !== '') ? props.value : ''} />
                      }}
                    />
                  </div>
                  <Button
                    style={{ marginLeft: 20, backgroundColor: "#7A63FF", borderWidth: 0, height: 45, marginTop: 25 }}
                    color="default"
                    type="button"
                    onClick={() => {
                      CompanyStore.resetFilter()
                    }}
                  >
                    {strings.clear_filters}
                  </Button>
                  <Button onClick={async () => {
                    set_show_modal(true)
                  }} style={{ marginLeft: "auto", backgroundColor: "#7A63FF", borderWidth: 0, height: 45, marginTop: 25 }}
                    color="default"
                    type="button" >{strings.edit_technicians}</Button>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  pagination={pagination}
                  bordered={false}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
      <Modal isOpen={show_modal}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => alert("hidden")}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{strings.edit_technicians}</h6>
        </div>
        <ModalBody>
          <Form style={{ marginTop: 10 }}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.name}
              </label>
              <Input
                value={CompanyStore.name}
                placeholder={strings.name}
                type="text"
                onChange={(e) => {
                  CompanyStore.setName(e.target.value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.surname}
              </label>
              <Input
                value={CompanyStore.surname}
                placeholder={strings.surname}
                type="text"
                onChange={(e) => {
                  CompanyStore.setSurname(e.target.value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.email}
              </label>
              <Input
                value={CompanyStore.email}
                placeholder={strings.email}
                type="text"
                onChange={(e) => {
                  CompanyStore.setEmail(e.target.value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.password}
              </label>
              <Input
                placeholder={strings.password}
                type="text"
                onChange={(e) => {
                  CompanyStore.setPassword(e.target.value)
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="default"
            type="button"
            onClick={async () => { await CompanyStore.updateTechnician(technician_id); set_show_modal(false); }}
          >
            {strings.save}
          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => set_show_modal(false)}
          >
            {strings.close}
          </Button>
        </div>
      </Modal>
    </>
  )
})

const RenderExcell = observer(({ type }) => {
  const { user } = userStore;
  const { calls, } = CompanyStore
  const filename = `Selffix - ${user.email} - ${moment().format("DD-MM:YYYY HH:mm")}`
  return (
    <ExcelFile filename={filename} element={<CardImg
      alt="..."
      src={require("../../assets/img/excel.png").default}
      style={{ width: 50, height: 50 }} />}>
      <ExcelSheet data={calls} name="Calls">
        <ExcelColumn label={strings.company} value="company" />
        <ExcelColumn label={strings.call_id} value="slug" />
        <ExcelColumn label={strings.technician} value="technician" />
        <ExcelColumn label={strings.start_call}
          value={(col) => moment(col.callStartedAt).format("DD-MM-YYYY HH:mm")} />
        <ExcelColumn label={strings.end_call}
          value={(col) => moment(col.callEndedAt).format("DD-MM-YYYY HH:mm")} />
        <ExcelColumn label={strings.call_duration} value={
          (col) => `${col.duration ? (col.duration.minutes < 10 ? `0${col.duration.minutes}` : col.duration.minutes) : "-"}:${col.duration ? (col.duration.seconds.toFixed(0) < 10 ? `0${col.duration.seconds.toFixed(0)}` : col.duration.seconds.toFixed(0)) : "-"}`
        } />
        <ExcelColumn label={strings.customer_phone} value="customer" />
        <ExcelColumn label={strings.tech_note} value={(col) => col.note ? col.note : "-"} />
        <ExcelColumn label={strings.final_condition} value="state" />
        <ExcelColumn label={strings.spare_part} value="sparePart" />
        <ExcelColumn label={strings.resolved} value={(col) => col.resolved ? strings.yes : strings.no} />
        <ExcelColumn label={strings.customers_rate} value={(col) => col.customerRating == "-1" ? strings.no_rating : col.customerRating} />
        <ExcelColumn label={strings.technicians_rate} value={(col) => col.technicianRating.rating == "-1" ? strings.no_rating : col.technicianRating.rating} />
        <ExcelColumn label={strings.technician_call_comments} value={(col) => !col.technicianRating.comment ? strings.no_rating : col.technicianRating.comment} />
        {/* <ExcelColumn label="CreatedAt"
          value={(col) => moment(col.createdAt).format("DD-MM-YYYY HH:mm")} /> */}
      </ExcelSheet>
    </ExcelFile>
  );
})


class Dashboard extends React.Component {
  async componentDidMount() {
    const type = this.props.location.pathname.split("/")
    const technician_id = type[type.length - 1]
    const { technician } = CompanyStore
    if (!technician) {
      CompanyStore.setTechnician(type[type.length - 1])
    }
    await CompanyStore.getTechnicians();
    await CompanyStore.getTechnicianDetail();


    CompanyStore.name = CompanyStore.technicians != null ? CompanyStore.technicians.find(x => x._id === technician_id).name : ""
    CompanyStore.surname = CompanyStore.technicians != null ? CompanyStore.technicians.find(x => x._id === technician_id).surname : ""
    CompanyStore.email = CompanyStore.technicians != null ? CompanyStore.technicians.find(x => x._id === technician_id).email : ""
  }

  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div style={{ display: 'flex', flex: 1, justifyContent: "space-between" }}>
                  <CardTitle tag="h4">{strings.technician_call_list}</CardTitle>
                  <RenderExcell />
                </div>
              </CardHeader>
              <Calls />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;

// const Dashboard = () => {

// };

// export default Dashboard;
