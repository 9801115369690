import { makeAutoObservable } from "mobx"
import Axios from "axios";
import moment from "moment"
import companyStore from "./companyStore";

class LiveStore {

  selectedBranch = null;
  selectedCompany = null;
  stats = null;
  calls = null;
  date1 = moment().subtract(7, 'd').format('DD/MM/YYYY')
  date2 = moment().format('DD/MM/YYYY')
  showLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getCalls = async () => {
    const user = JSON.parse(localStorage.getItem("user"))
    const body = {
      "startDate": moment(this.date1, "DD/MM/YYYY").startOf("day").valueOf(),
      "endDate": moment(this.date2, "DD/MM/YYYY").endOf("day").valueOf(),
      company: user.company ? user.company : this.selectedCompany,
      branch: this.selectedBranch ? this.selectedBranch._id : null
    }

    const resp = await Axios.post("stats/live-store/calls", body)
    this.calls = resp.data.calls;
  }

  setDate1 = async (date1) => {
    this.date1 = date1;
    this.showLoading = true;
    await this.getStats();
    await this.getCalls();
    this.showLoading = false;
    
  }

  setSelectedCompany = async (selectedCompany) => {
    this.selectedCompany = selectedCompany;
    const id = selectedCompany ? selectedCompany._id : null;
    this.showLoading = true;
    await companyStore.getBranchesWithId(id)
    await this.getStats()
    await this.getCalls()
    this.showLoading = false;
  }

  setDate2 = async (date2) => {
    this.date2 = date2;
    this.showLoading = true;
    await this.getStats()
    await this.getCalls()
    this.showLoading = false;
  }

  setSelectedBranch = async (selectedBranch) => {
    this.selectedBranch = selectedBranch;
    this.showLoading = true;
    await this.getStats()
    await this.getCalls()
    this.showLoading = false;
  }

  getStats = async () => {
    const user = JSON.parse(localStorage.getItem("user"))
    const body = {
      "startDate": moment(this.date1, "DD/MM/YYYY").startOf("day").valueOf(),
      "endDate": moment(this.date2, "DD/MM/YYYY").endOf("day").valueOf(),
      company: user.company ? user.company : this.selectedCompany,
      branch: this.selectedBranch ? this.selectedBranch._id : null
    }

    const resp = await Axios.post("stats/live-store", body)
    this.stats = resp.data.stats;
  }

}

const liveStore = new LiveStore();
export default liveStore;