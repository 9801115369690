import LocalizedStrings from 'react-localization';

import { en } from './langs/en';
import { tr } from './langs/tr';

let strings = new LocalizedStrings({
  en: en,
  tr: tr,
});

export default strings;