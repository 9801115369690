/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import UserStore from '../../../stores/userStore'
import strings from '../../../helpers/localization'
function Login() {
  return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "row", backgroundColor: 'white' }}>
        <div style={{ display: "flex", flex: 1, }}>
          <img
            style={{height:"100vh"}}
            alt="..."
            src={require("../../../assets/img/login_img.png").default}
          />
          <div style={{ position: "absolute", bottom: 200, left: 36, display: "flex", flexDirection: "column", right: '52vw' }}>
            <span style={{ color: "white", fontSize: "4vh" }}>{strings.welcome_selffix}</span>
            <span style={{ color: "#CBCBCB", fontSize: "2vh" }}>{strings.welcome_description}</span>
          </div>

        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img
              alt="..."
              src={require("../../../assets/img/loginIcon.png").default}
              style={{ position: "absolute", top: 36, right: 20, width: 200 }}
            />
            <span style={{ color: "#313131", fontSize: "1.8rem", fontWeight: "bold" }}>
              {strings.sign_in}
            </span>
            <span style={{ color: "#C9C9C9", fontSize: "1rem", marginTop: 2, marginBottom: 16 }}>
             {strings.welcome_description}
            </span>
            <span style={{ color: "#313131", marginBottom: 5 }}>
              {strings.email_address}
            </span>
            <Input
              placeholder={strings.email}
              type="email"
              onChange={(e) => {
                UserStore.setEmail(e.target.value)
              }}
            />
            <span style={{ color: "#313131", marginBottom: 5, marginTop: 5 }}>
              {strings.password}
            </span>
            <Input
              placeholder={strings.password}
              type="password"
              onChange={(e) => {
                UserStore.setPassword(e.target.value);
              }}
            />
            <button onClick={async () => {
              try{
                await UserStore.login()
              }catch(e){
                console.log(e);
              }
              
            }} style={{ marginTop: 10, width: "300px", height: "50px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1.2rem" }}>
              {strings.login}
            </button>
            
          </div>
        </div>
      </div>
  );
}

export default Login;
