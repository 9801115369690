import React, { cloneElement, Component } from 'react'
import { BrowserRouter as Router, Route, useParams, Redirect } from 'react-router-dom'
import { MessageBox } from "react-chat-elements";
import strings from "helpers/localization";
import { observer } from 'mobx-react';
import companyStore from '../../stores/companyStore';

class CallDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      resolved: false,
      state: "",
      note: "Notes",
      sparePart: "Spare Part",
    }
  }

  async componentDidMount() {
    await companyStore.getCallDetail(this.props.match.params.id);
    this.handleData();
  }

  handleSave = async () => {
    const { call } = companyStore;
    const {
      note,
      sparePart,
      state,
      resolved,
    } = this.state;

    const _call = {
      "call_id": call._id,
      "note": note,
      "state": state,
      "sparePart": sparePart,
      "resolved": resolved,
    };

    try {
      await companyStore.editCall(_call);
      alert("Changes Saved!");
    } catch (error) {
      console.log("error => ", error);
    }
  }

  handleData() {
    const { call } = companyStore;
    this.setState({
      resolved: call.resolved,
      sparePart: call.sparePart,
      note: call.note,
      state: call.state,
    })

  }

  render() {
    const { call, duration, messages } = companyStore;
    console.log(call);
    if(!call){
      return null;
    }
    const selectedColor = '#7A63FF';
    const defaultColor = '#E6E6E6';
    return (
      <div style={{ display: "flex", overflow: 'scroll', flex: 1, alignSelf: "stretch", height: "100%", backgroundColor: "#F3F6F9", justifyContent: "center", alignItems: "center" }}>
        <div style={{ flex: 2, alignSelf: "stretch", backgroundColor: "transparent", margin: 32 }}>
          <div style={{ backgroundColor: "white", padding: 16, display: "flex", alignItems: "center", justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: '#EBEBEB', borderBottomStyle: 'solid', paddingBottom: 10, }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#7A63FF', fontSize: 15, fontWeight: 'bold' }}>ID:</span>
              <span style={{ color: '#464A53', fontWeight: 'bold' }}>{call._id}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
              <span style={{ color: '#7A63FF', fontSize: 15, fontWeight: 'bold' }}>{strings.customer}:</span>
              <span style={{ color: '#464A53', fontWeight: 'bold' }}>{call.customer}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
              <span style={{ color: '#7A63FF', fontSize: 15, fontWeight: 'bold' }}>{strings.technician}:</span>
              <span style={{ color: '#464A53', fontWeight: 'bold' }}>{call.technician}</span>
            </div>
            {duration && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 150 }}>
              <span style={{ color: '#7A63FF', fontSize: 15, fontWeight: 'bold' }}>{strings.duration}:</span>
              <span style={{ color: '#464A53', fontWeight: 'bold' }}>{duration.minutes + " mins " + Math.ceil(duration.seconds) + " sec"}</span>
            </div>}
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#7A63FF', fontSize: 15, fontWeight: 'bold' }}>state</span>
              <span style={{ color: '#464A53', fontWeight: 'bold' }}>{this.state.state}</span>
            </div> */}
          </div>
          <div style={{ backgroundColor: "white", padding: 16, display: "flex", flexDirection: 'column', }}>
            <div>
              <span style={{ color: '#B8B8B8', fontWeight: 'bold', fontSize: 14 }}>Resolved:</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 120 }} onClick={() => this.setState({ resolved: true })}>
                  <div style={{ display: 'flex', width: 16, height: 16, borderRadius: 8, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.resolved ? selectedColor : defaultColor, marginRight: 10, justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.resolved && <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: '#7A63FF' }} />}
                  </div>
                  <span style={{ color: this.state.resolved ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.resolved}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 120 }} onClick={() => this.setState({ resolved: false })}>
                  <div style={{ display: 'flex', width: 16, height: 16, borderRadius: 8, borderWidth: 1, borderStyle: 'solid', borderColor: !this.state.resolved ? selectedColor : defaultColor, marginRight: 10, justifyContent: 'center', alignItems: 'center' }}>
                    {!this.state.resolved && <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: '#7A63FF' }} />}
                  </div>
                  <span style={{ color: !this.state.resolved ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.not_resolved}</span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <span style={{ color: '#B8B8B8', fontWeight: 'bold', fontSize: 14 }}>state:</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 120 }} onClick={() => this.setState({ state: "active" })}>
                  <div style={{ display: 'flex', width: 16, height: 16, borderRadius: 8, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.state === "active" ? selectedColor : defaultColor, marginRight: 10, justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.state === "active" && <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: '#7A63FF' }} />}
                  </div>
                  <span style={{ color: this.state.state === "active" ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.active}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 200 }} onClick={() => this.setState({ state: "customer_not_connect" })}>
                  <div style={{ display: 'flex', width: 16, height: 16, borderRadius: 8, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.state === "customer_not_connect" ? selectedColor : defaultColor, marginRight: 10, justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.state === "customer_not_connect" && <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: '#7A63FF' }} />}
                  </div>
                  <span style={{ color: this.state.state === "customer_not_connect" ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.customer_is_not_connect}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 120 }} onClick={() => this.setState({ state: "end" })}>
                  <div style={{ display: 'flex', width: 16, height: 16, borderRadius: 8, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.state === "end" ? selectedColor : defaultColor, marginRight: 10, justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.state === "end" && <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: '#7A63FF' }} />}
                  </div>
                  <span style={{ color: this.state.state === "end" ? selectedColor : defaultColor, fontWeight: '600' }}>{strings.end}</span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <span style={{ color: '#B8B8B8', fontWeight: 'bold', fontSize: 14 }}>Note</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <textarea value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} style={{ padding: 8, borderRadius: 4, marginRight: 16, marginTop: 16, height: "50%", width: '100%', borderWidth: "1px", borderColor: "#F0F0F0", outline: "none", resize: "none" }} name="message" rows="10" cols="30"></textarea>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <span style={{ color: '#B8B8B8', fontWeight: 'bold', fontSize: 14 }}>Spare Part</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <textarea value={this.state.sparePart} onChange={(e) => this.setState({ sparePart: e.target.value })} style={{ padding: 8, borderRadius: 4, marginRight: 16, marginTop: 16, height: "50%", width: '100%', borderWidth: "1px", borderColor: "#F0F0F0", outline: "none", resize: "none" }} name="message" rows="10" cols="30"></textarea>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div onClick={() => this.endLiveChat(false, false)} style={{ marginTop: 16, justifyContent: "center", alignItems: "center", display: "flex", alignSelf: "stretch", fontSize: "1rem", color: "#C9C9C9", fontWeight: "600", textAlign: "center", marginRight: 16 }}>
                {strings.cancel}
              </div>
              <div onClick={() => this.handleSave()} style={{
                marginTop: 16, justifyContent: "center", alignItems: "center", display: "flex", alignSelf: "stretch", fontSize: "1rem", color: "white", fontWeight: "600", backgroundColor: "#7A63FF", textAlign: "center", marginRight: 16, paddingTop: 5, paddingBottom: 5,
                paddingLeft: 35, paddingRight: 35, borderRadius: 4,
              }}>
                {strings.save}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1, alignSelf: "stretch", backgroundColor: "transparent", marginTop: 32, marginRight: 32, overflowY: 'scroll' }}>
          <div style={{ backgroundColor: "white", padding: 16, display: "flex", alignItems: "center", justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: '#EBEBEB', borderBottomStyle: 'solid', paddingBottom: 10, }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#7A63FF', fontSize: 22, fontWeight: 'bold' }}>Messages</span>
            </div>
          </div>
          <div style={{ backgroundColor: "white", padding: 16, display: "flex", flexDirection: 'column' }}>
            <div>
            </div>
          </div>
        </div>
        {messages.length > 0 &&
          <div style={{ flex: 1, alignSelf: "stretch", backgroundColor: "transparent", marginTop: 32, marginRight: 32, overflowY: 'scroll' }}>
            <div style={{ backgroundColor: "white", padding: 16, display: "flex", alignItems: "center", justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: '#EBEBEB', borderBottomStyle: 'solid', paddingBottom: 10, }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#7A63FF', fontSize: 22, fontWeight: 'bold' }}>Messages</span>
              </div>
            </div>
            <div style={{ backgroundColor: "white", padding: 16, display: "flex", flexDirection: 'column' }}>
              <div>
                {messages.map(item => {
                  if (item.from === "technician") {
                    return (
                      <MessageBox title={'Technician'} position={'left'}
                        date={new Date(item.createdAt)} text={item.message.type === "file" ? null : item.message.content}
                        type={item.message.type === "file" ? "photo" : 'text'}
                        data={{ uri: item.message.content }} titleColor={'#7A63FF'} />)
                  } else if (item.from === "customer") {
                    return (
                      <MessageBox title={'Customer'} position={'right'}
                        date={new Date(item.createdAt)} text={item.message.type === "file" ? null : item.message.content}
                        type={item.message.type === "file" ? "photo" : 'text'}
                        data={{ uri: item.message.content }} />)
                  }
                })}
              </div>
            </div>
          </div>}
      </div>

    )
  }
}

export default observer(CallDetail);

