import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
// react plugin used to create charts
import moment from 'moment';
import {
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardTitle,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import { observer } from 'mobx-react'
// core components
import paginationFactory from "react-bootstrap-table2-paginator";
import strings from "helpers/localization";
import liveStore from "../../../stores/liveStore";
import ReactDatetime from "react-datetime";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import ReactExport from "react-export-excel";
import { Link } from "react-router-dom";
import userStore from "../../../stores/userStore";
import companyStore from "../../../stores/companyStore";
import ClipLoader from "react-spinners/BounceLoader";

const { SearchBar } = Search;

const Loading = observer(() => {
  const { showLoading } = liveStore;
  if(!showLoading) return null;
  return(
    <div style={{display:"flex",justifyContent:"center",paddingTop:250,position:"absolute",left:0,right:0,top:0,bottom:0,backgroundColor:"rgba(255,255,255,0.8)"}}>
      <ClipLoader color={"#7A63FF"}/>
    </div>
  )
})

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 15,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {strings.show}{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        }
      </label>
    </div>
  )
});


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const RenderExcellStats = observer(({ type }) => {
  const { stats, selectedBranch } = liveStore;
  const branchName = selectedBranch ? selectedBranch.name : "ALL"
  const filename = `LiveStore - ${branchName} - ${moment().format("DD-MM:YYYY HH:mm")}`
  //"../../../assets/img/excel.png"
  return (
    <ExcelFile filename={filename} element={<img
      alt="..."
      src={require("../../../assets/img/excel.png").default}
      style={{ width: 15, height: 15, cursor: "pointer" }} />}>
      <ExcelSheet data={[stats]} name={`Stats - ${branchName}`}>

        <ExcelColumn label={"Accept Rate"} value="acceptRate" />
        <ExcelColumn label={"Accepted Call"} value="acceptedCall" />
        <ExcelColumn label={"Avg Call Duration"} value="avgCallDuration" />
        <ExcelColumn label={"Avg Waiting Duration"} value="avgWaitingDuration" />
        <ExcelColumn label={"Avg Waiting Duration For Accepted Calls"} value="avgWaitingDurationForAcceptedCalls" />
        <ExcelColumn label={"Max Waiting Duration"} value="maxWaitingDuration" />
        <ExcelColumn label={"Total Call"} value="totalCall" />
        <ExcelColumn label={"Total Call Duration"} value="totalCallDuration" />
        <ExcelColumn label={"Total Waiting Duration"} value="totalWaitingDuration" />
        <ExcelColumn label={"Total Waiting Duration For Accepted Calls"} value="totalWaitingDurationForAcceptedCalls" />
        <ExcelColumn label={"Avg Agent Ratings"} value="avgAgentRatings" />
        <ExcelColumn label={"Avg Customer Ratings"} value="avgCustomerRatings" />
      </ExcelSheet>
    </ExcelFile>
  );
})

const Branches = observer(() => {
  const { branches, company } = companyStore;
  if (!company) return null
  const id = company._id ? company._id : company;
  return (
    <CardBody>
      <ToolkitProvider
        data={branches}
        keyField="id"
        columns={[
          {
            dataField: "name",
            sort: true,
            text: strings.branch_name,
            formatter: (data, data2) => {
              const { user } = userStore;
              return (
                <Link to={`/${user.super ? "admin" : "supervisor"}/branch/detail/${id}/${data2._id}`} onClick={() => { companyStore.setBranch(data2._id) }}>
                  {data}
                </Link>
              )
            }
          },

          {
            dataField: "email",
            sort: true,
            text: strings.email,
          },
          {
            dataField: "prompter",
            text: strings.prompter,
            sort: true,
            formatter: (data) => {
              return (
                <span>
                  {data ? strings.true : strings.false}
                </span>
              )
            }
          },
          {
            dataField: "createdAt",
            sort: true,
            text: strings.created_date,
            formatter: (data) => {
              return (
                <a>
                  {moment(data).format("DD-MM-YYYY")}
                </a>
              )
            }
          },
          {
            dataField: "_id",
            sort: true,
            text: strings.actions,
            style: { display: "flex", justifyContent: "center", alignItems: "center" },
            formatter: (data) => {
              return (
                <a
                  onClick={async () => {
                    if (window.confirm(strings.deleteBranch)) {
                      await companyStore.deleteBranch(data);
                      window.location.reload();
                    } else {
                      console.log("-----");
                    }
                  }}
                  style={{ cursor: "pointer", alignSelf: "center" }} >
                  <i className="fas fa-trash" style={{ color: "red" }} ></i>
                </a>
              )
            }
          },
        ]}
        search
      >
        {props => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </CardBody>
  )
})


const RenderExcellCalls = observer(({ type }) => {
  const { calls, selectedBranch } = liveStore;
  const branchName = selectedBranch ? selectedBranch.name : "ALL"
  const filename = `LiveStore - ${branchName} - calls - ${moment().format("DD-MM:YYYY HH:mm")}`
  //"../../../assets/img/excel.png"
  return (
    <ExcelFile filename={filename} element={<img
      alt="..."
      src={require("../../../assets/img/excel.png").default}
      style={{ width: 15, height: 15, cursor: "pointer" }} />}>
      <ExcelSheet data={calls.slice()} name={`Calls - ${branchName}`}>

        <ExcelColumn label={"ID"} value="_id" />
        <ExcelColumn label={"Branch"} value={(col) => col.branch ? col.branch.name : "-"} />
        <ExcelColumn label={"Agent"} value={(col) => col.agent ? col.agent.name + " " + col.agent.surname : "-"} />
        <ExcelColumn label={"Customer Name"} value={(col) => col.customer ? col.customer.name + " " + col.customer.surname : "-"} />
        <ExcelColumn label={"Customer Phone Number"} value={(col) => col.customer ? col.customer.phone : "-"} />
        <ExcelColumn label={"Customer E-Mail"} value={(col) => col.customer ? col.customer.email : "-"} />
        <ExcelColumn label={"Created At"} value={(col) => moment(col.createdAt).format("DD-MM-YYYY HH:mm")} />
        <ExcelColumn label={"Waiting Time"} value="waitingTime" />
        <ExcelColumn label={"Active Time"} value="activeTime" />
        <ExcelColumn label={"Accepted"} value="accepted" />

        <ExcelColumn label={"Agent Rating"} value={(col) => col.agentRating.rating !== -1 ? col.agentRating.rating : "-"} />
        <ExcelColumn label={"Agent Comment"} value={(col) => col.agentRating.comment ? col.agentRating.comment : "-"} />

        <ExcelColumn label={"Customer Rating"} value={(col) => col.customerRating.rating !== -1 ? col.customerRating.rating : "-"} />
        <ExcelColumn label={"Customer Comment"} value={(col) => col.customerRating.comment ? col.customerRating.comment : "-"} />

      </ExcelSheet>
    </ExcelFile>
  );
})

const Calls = observer(() => {
  const { calls } = liveStore;
  if (!calls) return null;
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: 15,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => onSizePerPageChange(e.target.value)}
            >
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          }
        </label>
      </div>
    )
  });
  return (
    <Card style={{ marginLeft: 20, marginTop: 8 }}>
      <CardBody>
        <RenderExcellCalls />
        <ToolkitProvider
          data={calls}
          keyField="_id"
          search
          columns={[
            {
              dataField: "slug",
              text: "Slug"
            },
            {
              dataField: "branch",
              text: strings.branch_name,
              filterValue: (row) => {
                if (row) {
                  return row.name
                } else {
                  return "-"
                }
              },
              formatter: (row, cell) => {
                if (row) {
                  return row.name
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "agent",
              text: strings.agent_name,
              filterValue: (row) => {
                if (row) {
                  return row.name
                } else {
                  return "-"
                }
              },
              formatter: (row, cell) => {
                if (row) {
                  return row.name + " " + row.surname
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "customer",
              text: strings.customer_name,
              filterValue: (row) => {
                if (row) {
                  return row.name + " " + row.surname
                } else {
                  return "-"
                }
              },
              formatter: (row, cell) => {
                if (row) {
                  return row.name + " " + row.surname
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "customer",
              text: strings.customer_phone,
              filterValue: (row) => {
                if (row) {
                  return row.phone
                } else {
                  return "-"
                }
              },
              formatter: (row, cell) => {
                if (row && row.phone) {
                  return row.phone
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "customer",
              text: strings.customer_mail,
              formatter: (row, cell) => {
                if (row && row.email) {
                  return row.email
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "createdAt",
              text: strings.created_at,
              sort: true,
              formatter: (row, cell) => {
                if (row) {
                  return moment(row).format("DD-MM-YYYY HH:mm")
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "waitingTime",
              text: strings.waiting_time,
              sort: true,
            },
            {
              dataField: "activeTime",
              text: strings.active_time,
              sort: true,
            },
            {
              dataField: "accepted",
              text: strings.status,
              sort: true,
              formatter: (row) => {
                return <span style={{ padding: 3, paddingLeft: 5, paddingRight: 5, backgroundColor: row ? "#008450" : "#EFB700", color: "white", borderRadius: 5 }}>{row ? "Yanıtlandı" : "Yanıtlanmadı"}</span>
              }
            },
            {
              dataField: "agentRating",
              text: strings.agent_rating,
              sort: true,
              formatter: (row) => {
                return row.rating
              }
            },
            {
              dataField: "agentRating",
              text: strings.agent_comment,
              formatter: (row) => {
                if (row.comment) {
                  return row.comment.substring(0.30);
                } else {
                  return "-"
                }

              }
            },
            {
              dataField: "customerRating",
              text: strings.customer_rating,
              sort: true,
              formatter: (row) => {
                if (row.rating === -1) {
                  return "-"
                } else {
                  return row.rating
                }

              }
            },
            {
              dataField: "customerRating",
              text: strings.customer_comment,
              formatter: (row) => {
                if (row.comment) {
                  return row.comment
                } else {
                  return "-"
                }

              }
            },

          ]}
        >
          {props => (
            <div className="py-2 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
})

const Stats = observer(() => {
  const { stats, selectedBranch } = liveStore;
  if (!stats) return null;
  return (
    <Card style={{ marginLeft: 20, marginTop: 8 }}>
      <CardBody>
        <RenderExcellStats />
        <ToolkitProvider
          data={[stats]}
          keyField="acceptRate"
          columns={[
            {
              dataField: "totalCall",
              text: strings.total_call,
            },
            {
              dataField: "acceptedCall",
              text: strings.accepted_call,
            },
            {
              dataField: "acceptRate",
              text: strings.accept_rate,
              formatter: (data, data2) => {
                if (!data) return "-"
                return (
                  <span>
                    {(data * 100).toFixed(2)}%
                  </span>
                )
              },
            },
            {
              dataField: "totalCallDuration",
              text: strings.total_call_duration,
            },
            {
              dataField: "avgCallDuration",
              text: strings.avg_call_duration,
            },
            {
              dataField: "avgWaitingDuration",
              text: strings.avg_waiting_duration,
            },
            {
              dataField: "totalWaitingDuration",
              text: strings.total_waiting_duration,
            },
            {
              dataField: "avgWaitingDurationForAcceptedCalls",
              text: strings.avg_waiting_for_accepted,
            },
            {
              dataField: "maxWaitingDuration",
              text: strings.max_waiting_duration,
            },
            {
              dataField: "totalWaitingDurationForAcceptedCalls",
              text: strings.total_waiting_for_accepted,
            },
            {
              dataField: "avgAgentRatings",
              text: strings.avg_agent_rating,
              formatter: (cell, row) => {
                if (!cell) return "-";
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {cell.toFixed(2)}
                    <i style={{ color: "yellow" }} className="fa fa-star" />
                  </div>
                )
              }
            },
            {
              dataField: "avgCustomerRatings",
              text: strings.avg_customer_rating,
              formatter: (cell, row) => {
                if (!cell) return "-";
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {cell.toFixed(2)}
                    <i style={{ color: "yellow" }} className="fa fa-star" />
                  </div>
                )
              }
            },


            // {
            //   dataField: "isManager",
            //   text: strings.manager,
            //   sort: true,
            //   formatter: (data) => {
            //     return (
            //       <span>
            //         {data ? "true" : "false"}
            //       </span>
            //     )
            //   }
            // },
            // {
            //   dataField: "createdAt",
            //   sort: true,
            //   text: strings.created_date,
            //   formatter: (data) => {
            //     return (
            //       <a>
            //         {moment(data).format("DD-MM-YYYY")}
            //       </a>
            //     )
            //   }
            // },
            // {
            //   dataField: "_id",
            //   sort: true,
            //   text: strings.actions,
            //   style: { display: "flex", justifyContent: "center", alignItems: "center" },
            //   formatter: (data) => {
            //     return (
            //       <a
            //         onClick={async () => {
            //           if (window.confirm(strings.deleteBranch)) {
            //             await companyStore.deleteAgent(data);
            //             window.location.reload();
            //           } else {
            //             console.log("-----");
            //           }
            //         }}
            //         style={{ cursor: "pointer", alignSelf: "center" }} >
            //         <i className="fas fa-trash" style={{ color: "red" }} ></i>
            //       </a>
            //     )
            //   }
            // }
          ]}
        >
          {props => (
            <div className="py-2 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
})

class LiveStoreDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      supervisor: false,
      technician: false,
      show_modal: false,
      date1: '',
      date2: '',
      branch_modal: false,
      branch_name: "",
      branch_mail: "",
      branch_password: "",
      is_prompter: false,
      isLoading: true,
    }
  }
  // const { stats, selectedBranch, date1, date2 } = liveStore;
  // const { user } = userStore;
  // const { branches, companies } = companyStore;
  // const [isLoading, setLoading] = useState(true);

  // const [state, setState] = useState({
  //   supervisor: false,
  //   technician: false,
  //   show_modal: false,
  //   date1: '',
  //   date2: '',
  //   branch_modal: false,
  //   branch_name: "",
  //   branch_mail: "",
  //   branch_password: "",
  //   is_prompter: false,
  // })

  renderBranchModal = () => {
    return (
      <Modal isOpen={this.state.branch_modal}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({ branch_modal: false })}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{strings.create_branch}</h6>
        </div>
        <ModalBody>
          <Form style={{ marginTop: 10 }}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.branch_name}
              </label>
              <Input
                placeholder={strings.branch_name}
                type="text"
                onChange={(e) => {
                  this.setState({ branch_name: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.email}
              </label>
              <Input
                placeholder={strings.email}
                type="text"
                onChange={(e) => {
                  this.setState({ branch_mail: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.password}
              </label>
              <Input
                placeholder={strings.password}
                type="password"
                onChange={(e) => {
                  this.setState({ branch_password: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup style={{ display: "flex", marginLeft: 24, flexDirection: "row" }}>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.prompter}
              </label>
              <Input
                type="checkbox"
                value={this.state.is_prompter}
                onChange={(e) => {
                  this.setState({ is_prompter: e.target.checked });
                }}
              />
            </FormGroup >
          </Form>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="default"
            type="button"
            onClick={async () => {
              companyStore.createBranch(this.state.branch_name, this.state.is_prompter, this.state.branch_mail, this.state.branch_password);
              this.setState({ branch_modal: false, branch_name: "", branch_mail: "", branch_password: "", is_prompter: false });
            }}
          >
            {strings.create}
          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({ branch_modal: false, branch_name: "", branch_mail: "", branch_password: "", is_prompter: false })}
          >
            {strings.close}
          </Button>
        </div>
      </Modal>
    )
  }

  async componentDidMount() {
    const { user } = userStore;
    if (user && user.super) {
      await companyStore.getCompanies();
    } else if (user && !user.super) {
      liveStore.setSelectedCompany(user.company);
      companyStore.setCompany(user.company)
    }
    await liveStore.getStats();
    await companyStore.getTechnicians();
    await companyStore.getBranches();
    await companyStore.getCompanyStats();
    await liveStore.getCalls();
  }

  render() {
    const { stats, selectedBranch, date1, date2 } = liveStore;
    const { user } = userStore;
    const { branches, companies } = companyStore;
    return (
      <>
        {this.renderBranchModal()}
        <Row style={{}}>
          <Col style={{ alignItems: "center", display: "flex", justifyContent: "center", marginLeft: 5 }}>
            From:
            <ReactDatetime
              inputProps={{
                placeholder: "From",
                style: { height: '40px', borderColor: '#C9C9C9', borderRadius: 5 },
              }}
              timeFormat={false}
              onChange={(e) => { liveStore.setDate1(e) }}
              closeOnSelect
              timeConstraints={{ max: 20 }}
              value={date1}
              dateFormat={"DD/MM/YYYY"}
              renderInput={(props) => {
                return <input {...props} value={(date1 !== '') ? props.value : moment().subtract(7, 'd').format('DD/MM/YYYY')} />
              }}
            />
          </Col>
          <Col style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
            To:
            <ReactDatetime
              inputProps={{
                placeholder: "To",
                style: { height: '40px', borderColor: '#C9C9C9', borderRadius: 5 },
              }}
              timeFormat={false}
              onChange={(e) => { liveStore.setDate2(e) }}
              closeOnSelect
              value={date2}
              dateFormat={"DD/MM/YYYY"}
              renderInput={(props) => {
                return <input {...props} value={(date1 !== '') ? props.value : moment().subtract(7, 'd').format('DD/MM/YYYY')} />
              }}
            />
          </Col>
          <Col lg="5" />
          <Row style={{ marginRight: 15 }}>
            <Col>
              {user.super && <UncontrolledDropdown direction="down" style={{ marginLeft: 20, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: 15, marginRight: 8 }}>{strings.companies}</span>
                <DropdownToggle
                  caret
                  className="btn-block"
                  color="primary"
                  data-toggle="dropdown"
                  type="button"
                  style={{ height: 45, backgroundColor: '#7A63FF' }}
                >
                  {liveStore.selectedCompany ? liveStore.selectedCompany.name : strings.all}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={async (e) => { liveStore.setSelectedCompany(null) }}
                  >
                    {strings.all}
                  </DropdownItem>

                  {
                    companies.map((b, i) => {
                      return (
                        <DropdownItem
                          key={"branch#" + i}
                          onClick={async (e) => { liveStore.setSelectedCompany(b) }}
                        >
                          {b.name}
                        </DropdownItem>
                      )
                    })
                  }
                </DropdownMenu>
              </UncontrolledDropdown>}
            </Col>
            <Col>
              <UncontrolledDropdown direction="down" style={{ marginLeft: 20, flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: 15, marginRight: 8 }}>{strings.branches}</span>
                <DropdownToggle
                  caret
                  className="btn-block"
                  color="primary"
                  data-toggle="dropdown"
                  type="button"
                  style={{ height: 45, backgroundColor: '#7A63FF' }}
                >
                  {liveStore.selectedBranch ? liveStore.selectedBranch.name : strings.all}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={async (e) => { liveStore.setSelectedBranch(null) }}
                  >
                    {strings.all}
                  </DropdownItem>

                  {
                    branches.map((b, i) => {
                      return (
                        <DropdownItem
                          key={"branch#" + i}
                          onClick={async (e) => { liveStore.setSelectedBranch(b) }}
                        >
                          {b.name}
                        </DropdownItem>
                      )
                    })
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </Row>
        <Row>
          <Stats />
        </Row>
        <Row>
          <Calls />
        </Row>
        {liveStore.selectedCompany && <Row>
          <Card style={{ marginTop: 8, display: "flex", width: "100%", marginLeft: 20 }}>
            <CardHeader>
              <CardTitle tag="h4">{strings.branches}</CardTitle>
              <div>
                <button onClick={async () => {
                  this.setState({ branch_modal: true })
                }} style={{ marginRight: 20, height: "45px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1rem" }}>
                  {strings.create_branch}
                </button>
              </div>
            </CardHeader>
            <Branches />
          </Card>
          <Loading />
        </Row>}
      </>
    )
  }
}

export default observer(LiveStoreDetail);