/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link } from "react-router-dom";
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImg,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form
} from "reactstrap";

import { observer } from 'mobx-react'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import userStore from "../../stores/userStore";
import moment from "moment";
import companyStore from "../../stores/companyStore";
import ReactExport from "react-export-excel";
import strings from "helpers/localization";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 15,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {strings.show}{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        }
      </label>
    </div>
  )
});

const Columns = [
  {
    dataField: "name",
    text: strings.name,
    sort: true,
    formatter: (data, data2) => {
      const { user } = userStore;
      return (
        <Link to={`/${user.super ? "admin" : "supervisor"}/technician/detail/${data2._id}`} onClick={() => { companyStore.setTechnician(data2) }}>
          {data}
        </Link>
      )
    }
  },
  {
    dataField: "surname",
    sort: true,
    text: strings.surname
  },
  {
    dataField: "email",
    sort: true,
    text: strings.email
  },
  // {
  //   dataField: "company",
  //   text: "Company ID"
  // },
  {
    dataField: "createdAt",
    sort: true,
    text: strings.created_date,
    formatter: (data) => {
      return (
        <a>
          {moment(data).format("DD-MM-YYYY HH:mm")}
        </a>
      )
    }
  },
]

const Columns_1 = [
  {
    dataField: "_id",
    text: "ID",
    formatter: (data, data2) => {
      const { user } = userStore;
      return (
        <a>{data}</a>
      )
    }
  },
  {
    dataField: "name",
    text: strings.name
  },
  {
    dataField: "surname",
    text: strings.surname
  },
  {
    dataField: "email",
    text: strings.email
  },
  // {
  //   dataField: "company",
  //   text: "Company ID"
  // },
  {
    dataField: "createdAt",
    text: strings.created_date,
    formatter: (data) => {
      return (
        <a>
          {moment(data).format("DD-MM-YYYY")}
        </a>
      )
    }
  },
]

const Company = observer(({ type }) => {
  const { technicians, supervisors, selected_type } = companyStore
  if (selected_type === "Technicians") {
    if (!technicians || technicians.length === 0) return null;
    return (
      <CardBody>
        <ToolkitProvider
          data={technicians}
          keyField="id"
          columns={Columns}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    )
  } else {
    if (!supervisors || supervisors.length === 0) return null;
    return (
      <CardBody>
        <ToolkitProvider
          data={supervisors}
          keyField="id"
          columns={Columns_1}
          search
        >
          {props => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    )
  }
})


const CompanySelector = observer(({ type }) => {
  const { selected_type } = companyStore;
  return (
    <UncontrolledDropdown direction="down" style={{ marginRight: 20 }}>
      <DropdownToggle
        caret
        className="btn-block"
        color="primary"
        data-toggle="dropdown"
        type="button"
        style={{ height: 45, backgroundColor: '#7A63FF' }}
      >
        {selected_type === "Technicians" ? strings.technicians : strings.supervisors}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={async (e) => { await companyStore.setSelectedType("Technicians") }}
        >
          {strings.technicians}
        </DropdownItem>
        <DropdownItem
          onClick={async (e) => {
            await companyStore.setSelectedType("Supervisors")
          }}
        >
          {strings.supervisors}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
});

var buttonRef = null;
var buttonFlag = true;

const RenderExcell = observer(({ type }) => {
  const { companyExcell, company, technicians } = companyStore
  const filename = `Selffix - ${company} - ${moment().format("DD-MM:YYYY HH:mm")}`

  return (
    <ExcelFile filename={filename} element={<img ref={(ref) => buttonRef = ref} onClick={() => {
      companyStore.setExcel(); if (buttonFlag) {
        buttonFlag = false;
        setTimeout(() => {
          buttonRef.click()
        }, 500);
      }
    }}
      alt="..."
      src={require("../../assets/img/excel.png").default}
      style={{ width: 50, height: 50 }} />}>
      <ExcelSheet data={companyExcell} name="CompanyExcell">
        <ExcelColumn label={strings.company} value="company" />
        {/* <ExcelColumn label={strings.call_id} value="slug" />
        <ExcelColumn label={strings.technician} value="technician" />
        <ExcelColumn label={strings.start_call}
          value={(col) => moment(col.callStartedAt).format("DD-MM-YYYY HH:mm")} />
        <ExcelColumn label={strings.end_call}
          value={(col) => moment(col.callEndedAt).format("DD-MM-YYYY HH:mm")} />
        <ExcelColumn label={strings.call_duration} value={
          (col) => `${col.duration ? col.duration.minutes : "-"}:${col.duration ? col.duration.seconds.toFixed(0) : "-"}`
        } />
        <ExcelColumn label={strings.customer_phone} value="customer" />
        <ExcelColumn label={strings.final_condition} value="state" />
        <ExcelColumn label={strings.spare_part} value="sparePart" />
        <ExcelColumn label={strings.resolved} value={(col) => col.resolved ? strings.yes : strings.no} />
        {/* <ExcelColumn label="CreatedAt"
          value={(col) => moment(col.createdAt).format("DD-MM-YYYY HH:mm")} /> */}
      </ExcelSheet>
    </ExcelFile>
  );
})

const Branches = observer(() => {
  const { branches, company } = companyStore;
  const id = company._id ? company._id : company;
  return (
    <CardBody>
      <ToolkitProvider
        data={branches}
        keyField="id"
        columns={[
          {
            dataField: "name",
            sort: true,
            text: strings.branch_name,
            formatter: (data, data2) => {
              const { user } = userStore;
              return (
                <Link to={`/${user.super ? "admin" : "supervisor"}/branch/detail/${id}/${data2._id}`} onClick={() => { companyStore.setBranch(data2._id) }}>
                  {data}
                </Link>
              )
            }
          },

          {
            dataField: "email",
            sort: true,
            text: strings.email,
          },
          {
            dataField: "prompter",
            text: strings.prompter,
            sort: true,
            formatter: (data) => {
              return (
                <span>
                  {data ? "true" : "false"}
                </span>
              )
            }
          },
          {
            dataField: "createdAt",
            sort: true,
            text: strings.created_date,
            formatter: (data) => {
              return (
                <a>
                  {moment(data).format("DD-MM-YYYY")}
                </a>
              )
            }
          },
          {
            dataField: "_id",
            sort: true,
            text: strings.actions,
            style: { display: "flex", justifyContent: "center", alignItems: "center" },
            formatter: (data) => {
              return (
                <a
                  onClick={async () => {
                    if (window.confirm(strings.deleteBranch)) {
                      await companyStore.deleteBranch(data);
                      window.location.reload();
                    } else {
                      console.log("-----");
                    }
                  }}
                  style={{ cursor: "pointer", alignSelf: "center" }} >
                  <i className="fas fa-trash" style={{ color: "red" }} ></i>
                </a>
              )
            }
          },
        ]}
        search
      >
        {props => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </CardBody>
  )
})

class CompanyDetail extends React.Component {
  async componentDidMount() {
    const type = this.props.location.pathname.split("/")
    const { company } = companyStore
    if (!company) {
      companyStore.setCompany(type[type.length - 1])
    }
    await companyStore.getTechnicians();
    await companyStore.getCompanyStats();

    // await companyStore.getSupervisors();
  }

  constructor(props) {
    super(props);
    this.state = {
      supervisor: false,
      technician: false,
      show_modal: false,
      date1: '',
      date2: '',
      branch_modal: false,
      branch_name: "",
      branch_mail: "",
      branch_password: "",
      is_prompter: false,
    }
  }

  renderBranchModal = () => {
    return (
      <Modal isOpen={this.state.branch_modal}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({ branch_modal: false })}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">{strings.create_branch}</h6>
        </div>
        <ModalBody>
          <Form style={{ marginTop: 10 }}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.branch_name}
              </label>
              <Input
                placeholder={strings.branch_name}
                type="text"
                onChange={(e) => {
                  this.setState({ branch_name: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.email}
              </label>
              <Input
                placeholder={strings.email}
                type="text"
                onChange={(e) => {
                  this.setState({ branch_mail: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.password}
              </label>
              <Input
                placeholder={strings.password}
                type="password"
                onChange={(e) => {
                  this.setState({ branch_password: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup style={{ display: "flex", marginLeft: 24, flexDirection: "row" }}>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                {strings.prompter}
              </label>
              <Input
                type="checkbox"
                value={this.state.is_prompter}
                onChange={(e) => {
                  this.setState({ is_prompter: e.target.checked });
                }}
              />
            </FormGroup >
          </Form>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="default"
            type="button"
            onClick={async () => {
              companyStore.createBranch(this.state.branch_name, this.state.is_prompter, this.state.branch_mail, this.state.branch_password);
              this.setState({ branch_modal: false, branch_name: "", branch_mail: "", branch_password: "", is_prompter: false });
            }}
          >
            {strings.create}
          </Button>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({ branch_modal: false, branch_name: "", branch_mail: "", branch_password: "", is_prompter: false })}
          >
            {strings.close}
          </Button>
        </div>
      </Modal>
    )
  }

  render() {
    const { user } = userStore;
    const { selected_type, stats, key } = companyStore;
    const { date1, date2 } = this.state
    if (!stats) return null
    const {
      call,
      resolved,
      notResolved,
      customerNotConnect,
    } = stats;
    return (
      <div className="content">
        <Row>
          <Col lg="6" style={{ marginLeft: 24 }}>
            {/* <button onClick={async () => {
              await companyStore.createKey();
            }} style={{ marginRight: 20, height: "40px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "8vw", color: "white", fontSize: "1rem", marginTop: 20, marginRight: 40 }}>
              {strings.createKey}
            </button>
            {key && <button onClick={async () => {
            }} style={{}}>
              {strings.copyKey}
            </button>} */}
          </Col>
          <Col lg="1" style={{ marginTop: 30 }}>
            <span style={{ color: '#C9C9C9' }}>
              {strings.date_range}
            </span>
          </Col>
          <Col style={{ marginTop: 20 }}>
            <ReactDatetime
              inputProps={{
                placeholder: "From",
                style: { height: '40px', borderColor: '#C9C9C9', borderRadius: 5 },
              }}
              timeFormat={false}
              onChange={(e) => this.setState({ date1: e })}
              closeOnSelect
              dateFormat={"DD/MM/YYYY"}
              renderInput={(props) => {
                return <input {...props} value={(date1 !== '') ? props.value : moment().subtract(7, 'd').format('DD/MM/YYYY')} />
              }}
            />
            <img
              src={require("../../assets/img/calendar.png").default}
              style={{ width: 20, height: 20, position: 'absolute', top: 10, right: 25 }}
            />
          </Col>
          <div style={{ backgroundColor: '#EBEBEB', width: 1, height: '40px', marginTop: 20 }} />
          <Col style={{ marginTop: 20 }}>
            <ReactDatetime
              inputProps={{
                placeholder: "To",
                style: { height: '40px', borderColor: '#C9C9C9', borderRadius: 5 },
              }}
              timeFormat={false}
              onChange={(e) => this.setState({ date2: e })}
              closeOnSelect
              dateFormat={"DD/MM/YYYY"}
              renderInput={(props) => {
                return <input {...props} value={(date2 !== '') ? props.value : moment(new Date()).format('DD/MM/YYYY')} />
              }}
            />
            <img
              src={require("../../assets/img/calendar.png").default}
              style={{ width: 20, height: 20, position: 'absolute', top: 10, right: 25 }}
            />
          </Col>
          <button onClick={async () => { companyStore.getCompanyStats(date1, date2) }} style={{ marginRight: 20, height: "40px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "8vw", color: "white", fontSize: "1rem", marginTop: 20, marginRight: 40 }}>
            {strings.apply}
          </button>
        </Row>
        <Row style={{ marginTop: 20, marginRight: 10, marginLeft: 10 }}>
          <div className="custom-col-selffix">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      {strings.total_call}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {call}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="fas fa-phone-volume"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="custom-col-selffix">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      {strings.resolved_call}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {resolved}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="far fa-thumbs-up"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="custom-col-selffix">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      {strings.not_resolved}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {notResolved}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="fas fa-times"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="custom-col-selffix">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h5"
                    >
                      {strings.customer_not_connected}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {customerNotConnect}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="fas fa-plug"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div style={{ display: 'flex', flex: 1, justifyContent: "space-between" }}>
                  <CardTitle tag="h4">{selected_type === "Technicians" ? strings.company_technicians_list : strings.company_supervisor_list}</CardTitle>
                  <div>
                    {user.super && <Link to={`/admin/company/customize/${companyStore.company._id}`} style={{ marginRight: 20, padding: "12px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1rem" }}>
                      {strings.customize_remote_support}
                    </Link>
                    }
                    {user.super && selected_type === "Supervisors" && <button onClick={async () => {
                      this.setState({ show_modal: true, supervisor: true })
                    }} style={{ marginRight: 20, height: "45px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1rem" }}>
                      {strings.create_supervisors}
                    </button>}
                    {selected_type === "Technicians" && <button onClick={async () => {
                      this.setState({ show_modal: true, technician: true })
                    }} style={{ marginRight: 20, height: "45px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1rem" }}>
                      {strings.create_technician}
                    </button>}
                    {user.super && <CompanySelector />}
                    {/* <RenderExcell /> */}
                  </div>
                </div>
              </CardHeader>
              <Company />
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.show_modal}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ show_modal: false, supervisor: false, technician: false })}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">{selected_type === "Technicians" ? strings.create_technician : strings.create_supervisors}</h6>
          </div>
          <ModalBody>
            <Form style={{ marginTop: 10 }}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.name}
                </label>
                <Input
                  placeholder={strings.name}
                  type="text"
                  onChange={(e) => {
                    companyStore.setName(e.target.value)
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.surname}
                </label>
                <Input
                  placeholder={strings.surname}
                  type="text"
                  onChange={(e) => {
                    companyStore.setSurname(e.target.value)
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.email}
                </label>
                <Input
                  placeholder={strings.email}
                  type="text"
                  onChange={(e) => {
                    companyStore.setEmail(e.target.value)
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.password}
                </label>
                <Input
                  placeholder={strings.password}
                  type="text"
                  onChange={(e) => {
                    companyStore.setPassword(e.target.value)
                  }}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="default"
              type="button"
              onClick={async () => {
                this.setState({ show_modal: false, supervisor: false, technician: false });
                if (this.state.technician) {
                  await companyStore.createTechnician()
                } else {
                  await companyStore.createSupervisor()
                }
              }}
            >
              {strings.create}
            </Button>
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ show_modal: false, supervisor: false, technician: false })}
            >
              {strings.close}
            </Button>
          </div>
        </Modal>
      </div >
    );
  }
}

export default observer(CompanyDetail);

// const Dashboard = () => {

// };

// export default Dashboard;
