import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { Button, Card, CardBody, CardHeader, Col, CustomInput, FormGroup, Input, Row } from "reactstrap";
import strings from "helpers/localization";
import Axios from "axios";
import companyStore from "stores/companyStore";
import ClipLoader from "react-spinners/BounceLoader";

class CustomizeRemoteSupport extends React.Component {

  async componentDidMount() {
    await this.getAllData();
  }

  buildExampleMessage = (text) => {
    console.log("🚀 ~ CustomizeRemoteSupport ~ text:", text)
    if (!text.includes("${url}") || !text.includes("${pin}")) {
      if (this.state.sms.includePrivacy) {
        return strings.error_text_with_privacy;
      }
      return strings.error_text;
    }
    if (this.state.sms.includePrivacy && !text.includes("${privacy}")) {
      return strings.error_text_with_privacy;
    }

    const _t = text.replace("${url}", "https://example.com/lorem/ipsum");

    const _k = _t.replace("${privacy}", "https://example.com/privacy/open");

    return _k.replace("${pin}", "3939");

  }

  getAllData = async () => {
    const { companyId } = this.state;

    const { data } = await Axios.post("admin/company/options", {
      companyId
    });

    const { options } = data;
    
    this.setState({

      kvkk: options.kvkk,
      privacyURL: options.privacyURL,
      logo: options.logo,
      theme: options.theme,
      sms: options.sms,
    }, () => {
      this.setState({ exampleMessage: this.buildExampleMessage(options.sms.text) })
    })
  }

  constructor(props) {
    super(props);
    const companyId = this.props.match?.params?.companyId || JSON.parse(localStorage.getItem("user")).company;
    this.state = {
      companyId,
      loading: false,
      privacyURL: "",
      exampleMessage: "",
      sms: {
        provider: "",
        text: "",
        includePrivacy: false,
      },
      kvkk: {
        text: "",
        url: "",
        warning: ""
      },
      logo: "",
      theme: {
        primaryColor: ""
      }
    }
  }

  render() {
    console.log(this.state.sms.includePrivacy);
    return (
      <div className="content">
        <Row style={{ marginTop: 24 }}>
          <Col style={{ marginLeft: 18 }}>
            <Card>
              <CardHeader>
                {strings.colors}
              </CardHeader>
              <CardBody>
                <Input
                  value={this.state.theme.primaryColor}
                  placeholder={"Primary"}
                  type="text"
                  onChange={(e) => {
                    const { theme } = this.state;
                    theme.primaryColor = e.target.value;
                    this.setState({ theme });
                  }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col style={{ marginRight: 18 }}>
            <Card>
              <CardHeader>
                {strings.logo}
              </CardHeader>
              <CardBody>
                <Input
                  placeholder={"File"}
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    const { Location } = await companyStore.uploadFile(file, new Date().getTime());
                    this.setState({ logo: Location })

                  }}
                />
                {this.state.logo && <img src={this.state.logo} style={{ height: 50, width: 100, objectFit: "contain", marginTop: 24 }} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: 18, marginRight: 18 }}>
            <Card>
              <CardHeader>
                {strings.messages}
              </CardHeader>
              <CardBody>
                <Input
                  value={this.state.sms.text}
                  placeholder={"Message Template"}
                  type="text"
                  onChange={async (e) => {
                    const { sms } = this.state;
                    sms.text = e.target.value;
                    this.setState({ sms, exampleMessage: this.buildExampleMessage(e.target.value) });
                  }}
                />
                <span style={{ fontSize: 12 }}>{this.state.exampleMessage}</span>

                <div style={{ marginTop: 12 }}>

                  <FormGroup style={{ display: "flex", marginLeft: 24, flexDirection: "row" ,marginBottom:0}}>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      {strings.sms_privacy}
                    </label>
                    <Input
                      type="checkbox"
                      checked={this.state.sms.includePrivacy}
                      onChange={(e) => {
                        console.log("🚀 ~ CustomizeRemoteSupport ~ render ~ e:", e.target.value)
                        const { sms } = this.state;
                        sms.includePrivacy = e.target.checked
                        this.setState({ sms }, () => {
                          this.setState({ exampleMessage: this.buildExampleMessage(this.state.sms.text) })
                        })
                      }}
                    />
                  </FormGroup >

                </div>
                {this.state.sms.includePrivacy && <Input
                  value={this.state.privacyURL}
                  placeholder={strings.sms_privacy_url}
                  type="text"
                  onChange={async (e) => {
                    this.setState({ privacyURL: e.target.value });
                  }}
                />}

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: 18, marginRight: 18 }}>
            <Card>
              <CardHeader>
                {strings.gdpr}
              </CardHeader>
              <CardBody>
                <Input
                  value={this.state.kvkk.url}
                  placeholder={strings.privacy_url}
                  type="text"
                  onChange={(e) => {
                    const { kvkk } = this.state;
                    kvkk.url = e.target.value;
                    this.setState({ kvkk });
                  }}
                />
                <Input

                  value={this.state.kvkk.text}
                  placeholder={strings.privacy_text}
                  type="textarea"
                  style={{ marginTop: 12, height: 100 }}
                  onChange={(e) => {
                    const { kvkk } = this.state;
                    kvkk.text = e.target.value;
                    this.setState({ kvkk });
                  }}
                />
                <Input
                  value={this.state.kvkk.warning}
                  placeholder={strings.privacy_warning}
                  type="textarea"
                  style={{ marginTop: 12 }}
                  onChange={(e) => {
                    const { kvkk } = this.state;
                    kvkk.warning = e.target.value;
                    this.setState({ kvkk });
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginRight: 18, marginLeft: 18 }}>
            <Card>
              <CardBody style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button onClick={async () => {
                  this.setState({ loading: true });
                  const { companyId, kvkk, logo, sms, theme, privacyURL } = this.state;
                  const resp = await Axios.post("admin/company/setOptions", {
                    companyId,
                    options: {
                      kvkk,
                      logo,
                      sms,
                      privacyURL,
                      theme
                    }
                  });
                  this.setState({ loading: false });
                }} style={{ width: "50vh" }} color="primary">{strings.save}</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.loading && <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0, backgroundColor: "rgba(255,255,255,0.6)", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ClipLoader />
        </div>}
      </div>
    )
  }
}

export default CustomizeRemoteSupport;