export const en = {
	"welcome": "WELCOME!",
	"logout": "Logout",
	"welcome_selffix": "Welcome to SELFFIX",
	"welcome_description": "Sign in with your e-mail address and password",
	"sign_in": "Sign in",
	"email_address": "E-mail Address",
	"email": "Email",
	"password": "Password",
	"login": "Sign in",
	"home": "Home",
	"company_list": "Company List",
	"total_call": "TOTAL CALL",
	"resolved_call": "RESOLVED CALL",
	"not_resolved": "Not Resolved",
	"customer_not_connected": "CUSTOMER NOT CONNECTED",
	"customer_not_connect": "Customer Not Connect",
	"last_calls": "Last Calls",
	"calls": "CALLS",
	"calls_state_overview": "Calls State Overview",
	"resolve": "Resolve",
	"not_resolve": "Not Resolve",
	"not_connect": "Not Connect",
	"active": "Active",
	"companies": "Companies",
	"create_company": "Create Company",
	"create": "Create",
	"close": "Close",
	"id": "Id",
	"company_name": "Company Name",
	"name": "Name",
	"surname": "Surname",
	"supervisors_count": "Supervisors Count",
	"created_date": "Created Date",
	"company_technicians_list": "Company Technicians List",
	"create_technician": "Create Technician",
	"changes_saved": "Changes Saved",
	"technicians": "Technicians",
	"edit_technicians": "Edit Technician",
	"supervisors": "Supervisors",
	"create_supervisors": "Create Supervisor",
	"company_supervisor_list": "Company Supervisor List",
	"technician_call_list": "Technician Call List",
	"customer": "Customer",
	"duration": "Duration",
	"status": "Status",
	"resolved": "Resolved",
	"company": "Company",
	"call_id": "ID No.",
	"technician": "Technician",
	"start_call": "Start of Call (Date/Time)",
	"end_call": "End of Call (Date/Time)",
	"call_duration": "Call Duration",
	"customer_phone": "Customer Phone No.",
	"final_condition": "Final Condition",
	"spare_part": "Spare Part",
	"yes": "Yes",
	"no": "No",
	"show": "Show",
	"all": "All",
	"end": "End",
	"waiting": "Waiting",
	"resolve_status": "Resolve Status",
	"min_date": "Start Date",
	"max_date": "End Date",
	"clear_filters": "Clear Filters",
	"search": "Search",
	"select_language": "Select a Language",
	"turkish": "Turkish",
	"english": "English",
	"german": "German",
	"no_rating": "No Rating",
	"no_comment": "No Comment",
	"customers_rate": "Customer's Rate",
	"technicians_rate": "Technician's Rate",
	"technician_call_comments": "Technician Call Comment",
	"privacy_policy": "Privacy Policy URL",
	"tech_note": "Technician Note",
	"rating_avi": "Show Rating",
	"create_branch": "Create Branch",
	"branch_name": "Branch Name",
	"prompter": "Prompter",
	"branches": "Branches",
	"branch_agent_list": "Branch Agents List",
	"create_agent": "Create Agent",
	"manager": "Manager",
	"online": "Online",
	"createKey": "Create Key",
	"actions": "Actions",
	"deleteBranch": "Are you sure you want to delete this branch?",
	"deleteAgent": "Are you sure you want to delete this agent?",
	"agent_name": "Agent Name",
	"customer_name": "Customer Name",
	"customer_mail": "Customer E-Mail",
	"created_at": "Created At",
	"waiting_time": "Waiting Time",
	"active_time": "Active Time",
	"agent_rating": "Agent Rating",
	"agent_comment": "Agent Comment",
	"customer_rating": "Customer Rating",
	"customer_comment": "Customer Comment",
	"accepted_call": "Accepted Call",
	"total_call_duration": "Total Call Duration",
	"avg_call_duration": "Avg Call Duration",
	"avg_waiting_duration": "Avg Waiting Duration",
	"total_waiting_duration": "Total Waiting Duration",
	"avg_waiting_for_accepted": "Avg Waiting For Accepted",
	"max_waiting_duration": "Max Waiting Duration",
	"total_waiting_for_accepted": "Total Waiting For Accepted",
	"avg_agent_rating": "Avg Agent Rating",
	"avg_customer_rating": "Avg Customer Rating",
	"accept_rate": "Accept Rate",
	"TRUE": "TRUE",
	"FALSE": "FALSE",
	"remote_support": "Remote Support",
	"customize_remote_support": "Customize Remote Support",
	"colors": "Colors",
	"gdpr": "GDPR",
	"primary_color": "Primary Color",
	"privacy_url": "GDPR Url",
	"privacy_text": "GDPR Text",
	"privacy_warning": "GDPR Warning Text",
	"save": "Save",
	"messages": "Messages",
	"error_text": "Make sure that the text contains ${url} and ${pin} placeholders. Ex: Selffix Yardim Linki: ${url} Kod: ${pin}",
	"error_text_with_privacy": "Make sure that the text contains ${url} , ${pin} and ${privacy} placeholders. Ex: Selffix Yardim Linki: ${url} Kod: ${pin} Gizlilik:${privacy}",
	"sms_privacy": "Show the privacy policy in the sent SMS",
	"sms_privacy_url": "The address of the privacy policy to be included in the SMS",
	"date_range": "Date Range",
	"apply": "Apply",
	"file_size_large": "File is large for upload!",
	"logo": "Logo",
	"cancel": "Cancel"
}