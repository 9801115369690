import { makeAutoObservable } from "mobx"
import Axios from "axios";
import CompanyStore from './companyStore';
class UserStore {

  user = null;
  token = null;
  email = null;
  password = null;
  information = null;
  stats = {
    call: 0,
    duration: 0,
    avgDuration: 0,
    resolved: 0,
    notResolved: 0,
    active: 0,
    customerNotConnect: 0,
    resolveRatio: 0,
    notResolveRatio: 0,
    notConnectRatio: 0,
    activeRatio: 0,
  };
  lastCalls = []
  startDate = new Date();
  endDate = new Date();
  date1 = '';
  date2 = '';

  constructor() {
    makeAutoObservable(this);
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    this.token = token;
    this.user = JSON.parse(user);
    this.setToken(token);
    setTimeout(async () => {
      if (user && user.super) {
        await CompanyStore.getCompanies()
      }
    }, 1000);
  }

  setUser = (user) => {
    this.user = user;
  }

  logout = () => {
    this.user = null;
    this.token = null;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  setEmail = (email) => {
    this.email = email
  }

  setDate1 = (date) => {
    this.date1 = date;
  }

  setDate2 = (date) => {
    this.date2 = date;
  }

  getStatics = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { startDate, endDate, date1, date2 } = this;
        const _date1 = date1 ? new Date(date1).getTime() : startDate.getTime() - (1000 * 604800)
        const _date2 = date2 ? new Date(date2).getTime() : endDate.getTime()
        const response = await Axios.post("stats/admin", {
          startDate: _date1,
          endDate: _date2,
        });
        const { stats, lastCalls } = response.data;
        this.stats = stats;
        this.lastCalls = lastCalls;
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  setPassword = (password) => {
    this.password = password;
  }

  login = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { email, password } = this;
        const response = await Axios.post("auth/admin", { email, password });
        if(response.name === "Error"){
          alert("Email or Password is wrong!");
          return;
        }
        if (response.data && response.data.user.token) {
          localStorage.setItem("token", response.data.user.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.setToken(response.data.user.token)
          this.setUser(response.data.user);
          this.token = response.data.user.token
        }
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }

  setToken = (token) => {
    Axios.defaults.headers.Authorization = token;
  }

  createTechnician = async (name, surname, email, password, company) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post("admin/createTechnician", { name, surname, email, password, company });
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }
}

const userStore = new UserStore();
export default userStore;