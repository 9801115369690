/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
  CustomInput,
  Input,
  Form,
  FormGroup,
} from "reactstrap";

import { observer } from 'mobx-react'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import userStore from "../../stores/userStore";
import moment from "moment";
import CompanyStore from "../../stores/companyStore";
import strings from "helpers/localization";
import Rating from "react-rating"

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 15,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
        }
      </label>
    </div>
  )
});

const Columns = [
  {
    dataField: "name",
    sort: true,
    text: strings.company_name,
    formatter: (data, data2) => {
      const { user } = userStore;
      return (
        <Link to={`/${user.super ? "admin" : "supervisor"}/company/detail/${data2._id}`} onClick={() => { CompanyStore.setCompany(data2) }}>
          {data}
        </Link>
      )
    },
  },
  {
    dataField: "supervisors.length",
    sort: true,
    text: strings.supervisors_count,
    formatter: (data) => {
      return (
        <a>
          {data}
        </a>
      )
    }
  },
  {
    dataField: "createdAt",
    sort: true,
    text: strings.created_date,
    formatter: (data) => {
      return (
        <a>
          {moment(data).format("DD-MM-YYYY")}
        </a>
      )
    }
  },
  // {
  //   dataField: "createdAt",
  //   text: strings.customers_rate,
  //   formatter: (data) => {
  //     return (
  //       <div style={{ display: 'flex', alignItems: 'center', }}>

  //         <Rating
  //           initialRating={2.3}
  //           readonly
  //           fractions={10}
  //           emptySymbol={<img
  //             src={require("../../assets/img/star_empty.png").default}
  //             style={{ width: 18, height: 18, marginLeft: 2 }}
  //           />}
  //           fullSymbol={<img
  //             src={require("../../assets/img/star_full.png").default}
  //             style={{ width: 18, height: 18, marginLeft: 2 }}
  //           />}
  //         />
  //         <span style={{ marginLeft: 10, marginTop: 2 }}>
  //           2.3
  //         </span>
  //       </div>
  //     )
  //   }
  // },
  // {
  //   dataField: "createdAt",
  //   text: strings.technicians_rate,
  //   formatter: (data) => {
  //     return (
  //       <div style={{ display: 'flex', alignItems: 'center', }}>
  //         <Rating
  //           initialRating={3.7}
  //           readonly
  //           fractions={10}
  //           emptySymbol={<img
  //             src={require("../../assets/img/star_empty.png").default}
  //             style={{ width: 18, height: 18, marginLeft: 2 }}
  //           />}
  //           fullSymbol={<img
  //             src={require("../../assets/img/star_full.png").default}
  //             style={{ width: 18, height: 18, marginLeft: 2 }}
  //           />}
  //         />
  //         <span style={{ marginLeft: 10, marginTop: 2 }}>
  //           3.7
  //         </span>
  //       </div>
  //     )
  //   }
  // }
]

const Companies = observer(({ type }) => {
  const { companies } = CompanyStore
  if (!companies || companies.length === 0) return null;
  return (
    <CardBody>
      <ToolkitProvider
        data={companies}
        keyField="id"
        columns={Columns}
        search

      >
        {props => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </CardBody>
  )
})


class Dashboard extends React.Component {
  async componentDidMount() {
    await CompanyStore.getCompanies();
  }
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false
    }
  }

  handleChange = (key, event) => {
    if (event.target.files[0].size > 4000) {
      alert(strings.file_size_large);
      this.imageInput.value = ''
    };
  };


  render() {
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div style={{ display: 'flex', flex: 1, justifyContent: "space-between" }}>
                  <CardTitle tag="h4">{strings.companies}</CardTitle>
                  <button onClick={async () => {
                    this.setState({ show_modal: true })
                  }} style={{ width: "175px", height: "35px", backgroundColor: "#7A63FF", borderWidth: 0, borderRadius: 4, width: "16vw", color: "white", fontSize: "1rem" }}>
                    {strings.create_company}
                  </button>
                </div>
              </CardHeader>
              <Companies />
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.show_modal}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => alert("hidden")}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">{strings.create_company}</h6>
          </div>
          <ModalBody>
            <Form style={{ marginTop: 10 }}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.company_name}
                </label>
                <Input
                  placeholder={strings.company_name}
                  type="text"
                  onChange={(e) => {
                    CompanyStore.setCompanyName(e.target.value)
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.select_language}
                </label>
                <Input id="exampleFormControlSelect1" type="select" onChange={(e) => {
                  CompanyStore.setCompanyLang(e.target.value)
                }}>
                  <option value={"en"}>{strings.select_language}</option>
                  <option value={"tr"}>{strings.turkish}</option>
                  <option value={"en"}>{strings.english}</option>
                  <option value={"de"}>{strings.german}</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {strings.privacy_policy}
                </label>
                <Input
                  placeholder={strings.privacy_policy}
                  type="text"
                  onChange={(e) => {
                    CompanyStore.setPrivacyPolicy(e.target.value)
                  }}
                />
              </FormGroup>
              <FormGroup>
                <div style={{ display: "flex" }}>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {strings.rating_avi}
                  </label>
                  <div style={{ marginLeft: 42 }}>
                    <CustomInput onChange={(e) => {
                      CompanyStore.setShowRating(e.target.checked);
                    }} type="switch" id="exampleCustomSwitch" name="customSwitch" />
                  </div>

                </div>
              </FormGroup>
              {/* <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  Company Icon
                      </label>
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="customFileLang"
                    lang="en"
                    type="file"
                    ref={(el) => this.imageInput = el}
                    onChange={event => this.handleChange("image", event)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFileLang"
                  >
                    Select file
                      </label>
                </div>
              </FormGroup>
              */}
            </Form>
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="default"
              type="button"
              onClick={async () => { await CompanyStore.createCompany(); this.setState({ show_modal: false }) }}
            >
              {strings.create}
            </Button>
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ show_modal: false })}
            >
              {strings.close}
            </Button>
          </div>
        </Modal>

      </div>
    );
  }
}

export default Dashboard;

// const Dashboard = () => {

// };

// export default Dashboard;
